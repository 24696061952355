import { useEffect, useState } from "react";
import LCButton from "../components/LCButton";
import LCInputPin from "../components/LCInputPin";
import { VERIFICATION_STATUS_SUCCESS, VERIFY_WITH_BVN } from "../constants/screen";
import LCNavbar from "../containers/LCNavbar";
import { getCountryPhoneCode, maskPhoneNumber } from "../utility";
import { useAppContext } from "src/context/AppContext";
import { useTransactionContext } from "src/context/TransactionContext";
import { useMutation } from "@tanstack/react-query";
import { verifyID, verifyIDOTP } from "src/api";
import { useCountDown } from "src/hooks/useCountdown";

interface PropType {
	idPhoneNumberSubmitted: string;
	ninSubmitted: string;
	bvnSubmitted: string;
}
interface IResendProps {
	setVerificationCode: React.Dispatch<React.SetStateAction<string>>;
	sendCode: (mode: "sms" | "whatsapp") => void;
	counter: number;
	isCounting: boolean;
	isResendingOTP: boolean;
}

const VerifyIDVerificationCode = ({ idPhoneNumberSubmitted, ninSubmitted, bvnSubmitted }: PropType) => {
	const { previousScreen, setCurrentScreen, setToastMessage, setErrorMessage } = useAppContext();
	const { info, transactionRef } = useTransactionContext();
	const [counter, start, , , isCounting] = useCountDown(30);

	const [verificationCode, setVerificationCode] = useState("");

	const { mutate: resendOTP, isLoading: isOTPResending } = useMutation(verifyID, {
		onSuccess: () => {
			setToastMessage("OTP resent");
			start();
		},
		onError: ({ message }: any) => setErrorMessage(message),
	});
	const { mutate: verifyOTP, isLoading: isVerifyingOTP } = useMutation(verifyIDOTP, {
		onSuccess: () => setCurrentScreen(VERIFICATION_STATUS_SUCCESS),
		onError: ({ message }: any) => setErrorMessage(message),
	});

	useEffect(() => {
		start();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isVerificationCodeValid = () => verificationCode.trim().length === 6;

	const handleContinueClick = () => verifyOTP({ reference: transactionRef, otp: verificationCode });

	const sendCode = (alert_type: "whatsapp" | "sms") =>
		resendOTP({
			reference: transactionRef,
			type: "bvn",
			id_number: bvnSubmitted,
			phone_number: getCountryPhoneCode(info?.country_code)?.concat(idPhoneNumberSubmitted) || "ss",
			verification_alert_type: alert_type,
		});

	const handleBackClick = () => setCurrentScreen(previousScreen);

	return (
		<div className="flex flex-col">
			<LCNavbar hasBack handleBackClick={handleBackClick} />
			<div className="flex flex-col items-center justify-between w-full">
				<div className="w-full mb-10 text-center">
					<div className="mb-4 space-y-2">
						<h1 className="text-base font-semibold text-black">Verification Code</h1>
						<p className="text-xs text-black-80 w-full max-w-[171px] mx-auto">
							We sent a 6-digit code to <br />
							{previousScreen === VERIFY_WITH_BVN ? (
								<b>+234{maskPhoneNumber(idPhoneNumberSubmitted)}</b>
							) : (
								`a number ending with ${idPhoneNumberSubmitted}`
							)}
						</p>
					</div>
					<div className="space-y-4">
						<LCInputPin length={6} onChange={setVerificationCode} />
						<div className="flex items-center justify-center space-x-1 text-xs">
							<p className="font-semibold text-black-80">Didn&apos;t receive it?</p>
							<ResendCode
								setVerificationCode={setVerificationCode}
								sendCode={sendCode}
								counter={counter}
								isCounting={isCounting}
								isResendingOTP={isOTPResending}
							/>
						</div>
						<div className="flex items-center justify-center w-full">
							<ResendCodeWhatsApp
								setVerificationCode={setVerificationCode}
								sendCode={sendCode}
								counter={counter}
								isCounting={isCounting}
								isResendingOTP={isOTPResending}
							/>
						</div>
					</div>
				</div>
			</div>
			<LCButton
				isDisabled={!isVerificationCodeValid()}
				isLoading={isOTPResending || isVerifyingOTP}
				text="Continue"
				onClick={handleContinueClick}
			/>
		</div>
	);
};

const ResendCodeWhatsApp = ({ setVerificationCode, sendCode, counter, isCounting, isResendingOTP }: IResendProps) => {
	const handleResendCode = () => {
		setVerificationCode("");
		sendCode("whatsapp");
	};

	if (isResendingOTP) return null;

	if (isCounting)
		return (
			<button disabled className="flex items-center justify-center space-x-1 text-sm outline-none text-[#29773E]">
				<i className="ri-whatsapp-line ri-lg"></i>
				<p className="font-semibold">Send code via WhatsApp</p>
			</button>
		);

	return (
		<button onClick={handleResendCode} className="flex items-center justify-center space-x-1 text-sm outline-none text-[#29773E]">
			<i className="ri-whatsapp-line ri-lg"></i>
			<p className="font-semibold">Send code via WhatsApp</p>
		</button>
	);
};

const ResendCode = ({ setVerificationCode, sendCode, counter, isCounting, isResendingOTP }: IResendProps) => {
	const { colour } = useAppContext();

	const handleResendCode = () => {
		setVerificationCode("");
		sendCode("sms");
	};

	if (isResendingOTP)
		return (
			<div>
				<div className="animate-spin">
					<i className="ri-loader-4-line" style={{ color: colour.default }} />
				</div>
			</div>
		);

	if (isCounting)
		return (
			<>
				<p className="font-medium" style={{ color: colour.default }}>
					Resend in {counter}s
				</p>
			</>
		);

	return (
		<button type="button" onClick={handleResendCode} className="font-medium border-none outline-none" style={{ color: colour.dark }}>
			Resend code
		</button>
	);
};

export default VerifyIDVerificationCode;
