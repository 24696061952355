import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { setDestination } from "src/api";
import LCButton from "src/components/LCButton";
import LCDropdown from "src/components/LCDropdownNormal";
import LCInput from "src/components/LCInput";
import LCTitleSubtitle from "src/components/LCTitleSubtitle";
import { INITIATE_PURCHASE, SEND_TO_WALLET_ADDRESS } from "src/constants/screen";
import LCNavbar from "src/containers/LCNavbar";
import { useAppContext } from "src/context/AppContext";
import { useTransactionContext } from "src/context/TransactionContext";
import { getCountryPhoneCode } from "src/utility";

interface TProps {
	options: any[];
}

const ReceiveWithMobile = ({ options }: TProps) => {
	const { setCurrentScreen, setErrorMessage } = useAppContext();
	const { transactionRef, setSubmittedDetails, info } = useTransactionContext();

	const { mutate, isLoading } = useMutation(setDestination, {
		onSuccess: async () => setCurrentScreen(SEND_TO_WALLET_ADDRESS),
		onError: (error: any) => setErrorMessage(error.message),
	});

	const [networkProvider, setNetworkProvider] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");

	const handleContinueClick = () => {
		const countryCode = getCountryPhoneCode(info?.country_code) || "";
    const internationalPhoneNumber = countryCode.concat(phoneNumber);

		mutate({ reference: transactionRef, destination_type: "mobile_money", phone_network: networkProvider, phone_number: internationalPhoneNumber });

		setSubmittedDetails((prevState: any) => ({ ...prevState, delivery_to: getCountryPhoneCode(info?.country_code)?.concat(" ", phoneNumber) }));
	};
	const handleBackClick = () => setCurrentScreen(INITIATE_PURCHASE);

	return (
		<div>
			<LCNavbar hasBack handleBackClick={handleBackClick} />
			<div className="flex flex-col gap-6">
				<div className={`text-center w-full`}>
					<LCTitleSubtitle title="Mobile money details" subtitle="Kindly provide your phone number and network provider" />
				</div>
				<LCDropdown
					onOptionChange={(value) => setNetworkProvider(value.id)}
					dropdownTitle={"Network Provider"}
					dropdownPlaceholder={"Select provider"}
					options={options}
					isDisabled={false}
				/>
				<LCInput
					type="tel"
					label="Phone Number"
					placeholder="8123456789"
					value={phoneNumber}
					onChange={(e) => {
						setPhoneNumber(e.target.value);
					}}
				/>
				<LCButton
					type="submit"
					text="Continue"
					onClick={handleContinueClick}
					isDisabled={!networkProvider || phoneNumber.length < 8}
					isLoading={isLoading}
				/>
			</div>
		</div>
	);
};

export default ReceiveWithMobile;
