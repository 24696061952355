import { ReactNode } from "react";
import { sendMessageToParent } from "../utility";
import LCButton from "./LCButton";
import { useAppContext } from "src/context/AppContext";

type LCWidgetContainerPropType = {
	transaction: any;
	transactionRef: string;
	children: ReactNode;
};
const LCWidgetContainer = ({ transaction, children, transactionRef }: LCWidgetContainerPropType) => {
	const { isPopup, errorMessage, toastMessage, colour } = useAppContext();

	const handleModalCloseClick = () => {
		if (!window.confirm("Are you sure you want to close this widget?")) return;
		if (isPopup) {
			sendMessageToParent({ key: "sell-closeModal" });
		} else {
			window.location = `${
				transaction?.callback_url || transaction?.info?.callback_url || "https://localramp.co"
			}?ref=${transactionRef}` as string & Location;
		}
	};
	return (
		<div className={`w-full mb-4 sm:mb-0 ${isPopup ? "h-full" : "min-h-screen bg-white sm:bg-[#FAFAFA]"}`}>
			<div className={`${!isPopup ? "max-w-[356px] w-full sm:pt-32" : "max-w-[380px] w-full pt-6 sm:pt-32"} mx-auto relative`}>
				<div className="flex items-start space-x-2">
					<div className="flex flex-col items-center w-full space-y-3">
						<div
							className={`relative bg-white ${
								!isPopup
									? "w-full max-w-[324px] sm:border sm:border-[#E7E5E5] sm:rounded-2xl"
									: "w-full max-w-[300px] border border-[#E7E5E5] rounded-2xl"
							} ${isPopup ? "min-h-[356px]" : "min-h-[452px]"} mx-auto`}
						>
							{errorMessage || toastMessage ? (
								<div className="absolute left-0 right-0 flex items-center justify-center transition" style={{ zIndex: "60" }}>
									<div className="inline-block px-2 py-2 mt-4 bg-white rounded-md shadow-md text-red/75">
										<div className="flex items-center justify-between space-x-1">
											{errorMessage ? (
												<>
													<i className="ri-error-warning-fill ri-xl"></i>
													<p className="text-sm font-medium text-left">{errorMessage}</p>
												</>
											) : (
												<>
													<p className="text-sm font-medium text-left" style={{ color: colour.dark }}>
														{toastMessage}
													</p>
												</>
											)}
										</div>
									</div>
								</div>
							) : null}
							<div className={`px-7 ${isPopup ? "py-4" : "py-7"} h-full`}>{children}</div>
						</div>
						<a href="https://localramp.co" target="_blank" rel="noreferrer">
							<p className={`text-sm font-semibold ${isPopup ? "text-white" : "text-black"}`}>
								Powered by <span className="font-bold phonk">LocalRamp</span>
							</p>
						</a>
					</div>
					<div className="hidden sm:block">
						<button
							onClick={handleModalCloseClick}
							className="w-6 h-6 bg-[#FFFFFF8F] border border-[#E7E5E5] rounded-full flex items-center justify-center"
						>
							<i className="ri-close-fill ri-md" />
						</button>
					</div>
				</div>
			</div>
			<div className="mx-auto mt-4 w-44 sm:hidden">
				<LCButton isGray text="Close Modal" onClick={handleModalCloseClick} />
			</div>
		</div>
	);
};

export default LCWidgetContainer;
