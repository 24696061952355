import { useEffect, useState } from "react";
import LCBack from "../components/LCBack";
import LCScreenLoader from "../components/LCScreenLoader";
import LCTransactionListItem from "../components/LCTransactionListItem";
import TransactionWarning from "./TransactionWarning";
import { useAppContext } from "src/context/AppContext";
import { useTransactionContext } from "src/context/TransactionContext";
import { useQuery } from "@tanstack/react-query";
import { getTransactionHistory } from "src/api";
import { getFromStorageLocal } from "src/utility/storage";
import ReactPaginate from "react-paginate";

const TransactionHistory = ({ handleHomeClick }: { handleHomeClick: () => void }) => {
	const { setIsMenuOpen } = useAppContext();
	const { info } = useTransactionContext();

	const availableEmail = info?.email;
	const token = getFromStorageLocal(availableEmail);
	const [page, setPage] = useState(1);

	const { data, isLoading, refetch, isError } = useQuery({
		queryKey: ["transaction-history", page],
		queryFn: () => getTransactionHistory(token || "", { page }),
		enabled: !!token,
	});

	useEffect(() => {
		setIsMenuOpen(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const transactions = data?.data?.data;
	const pagination = data?.data?.pagination;

	if (isLoading) return <LCScreenLoader />;

	if (isError) return <TransactionWarning inScreen errorMessage={"Could get transaction history"} handleRetry={() => refetch()} />;

	return (
		<div>
			<div className="py-2">
				<LCBack text="Transaction History" handleClick={handleHomeClick} />
			</div>
			<div>
				<div className="mb-4 h-[302px]">
					{!transactions?.length ? (
						<div className="min-h-[260px] flex flex-col items-center justify-center">
							<p className="font-bold grotesk text-black-20">No transaction history</p>
						</div>
					) : (
						<>
							<div className="h-[280px] overflow-y-scroll mb-5">
								{transactions?.map((record: any, i: number) => (
									<LCTransactionListItem
										key={i}
										receiverCurrency={record?.to_currency}
										createdAt={record?.created_at}
										senderCurrency={record?.from_currency}
										sentAmount={record?.from_amount}
										receivedAmount={record?.to_amount}
									/>
								))}
							</div>

							<div className="space-y-2">
								<ReactPaginate
									breakLabel="..."
									nextLabel={<i className="ri-arrow-right-s-line ri-lg" />}
									forcePage={pagination.page - 1}
									onPageChange={(pn) => {
										setPage(pn.selected + 1);
									}}
									pageRangeDisplayed={10}
									pageCount={Math.ceil((pagination?.count || 0) / pagination?.limit)}
									previousLabel={<i className="ri-arrow-left-s-line ri-lg" />}
									nextClassName="lr-pagination-next"
									previousClassName="lr-pagination-previous"
									containerClassName="lr-pagination"
									pageClassName="lr-pagination-item"
									activeClassName="lr-pagination-item_active"
									renderOnZeroPageCount={null}
								/>
								<p className="text-xs font-semibold grotesk">
									Showing <b>{transactions.length}</b> of <b>{pagination.count}</b> entries
								</p>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default TransactionHistory;
