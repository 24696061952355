import { useEffect, useState } from "react";
import LCProcessStatus from "../components/LCProcessStatus";
import { ID_VERIFICATION, SEND_TO_WALLET_ADDRESS } from "../constants/screen";
import { DOCUMENT_VERIFICATION_STATE } from "./VerifyWithGovIssuedID";
import { useAppContext } from "src/context/AppContext";
import { useTransactionContext } from "src/context/TransactionContext";
import LCButton from "src/components/LCButton";

const VerificationStatus = ({ state }: { state?: boolean }) => {
	const { colour, setCurrentScreen } = useAppContext();
	const { info } = useTransactionContext();
	const [isSuccess, setIsSuccess] = useState(state);

	useEffect(() => {
		if (info?.document_verification_state === DOCUMENT_VERIFICATION_STATE.FAILED) {
			setIsSuccess(false);
		} else if (info?.document_verification_state === DOCUMENT_VERIFICATION_STATE.PASSED) {
			setIsSuccess(true);
		}
	}, [info]);

	return (
		<div className="w-full h-[396px] flex flex-col items-center justify-center">
			<LCProcessStatus
				isSuccess={!!isSuccess}
				successTitle="Verification Successful"
				failTitle="Verification Failed"
				successActionComponent={<LCButton text="Continue with transaction" onClick={() => setCurrentScreen(SEND_TO_WALLET_ADDRESS)} />}
				failActionComponent={
					<button onClick={() => setCurrentScreen(ID_VERIFICATION)}>
						<div className="flex items-center justify-center space-x-2" style={{ color: colour.dark }}>
							<i className="ri-restart-line"></i>
							<p className="font-semibold">Retry verification</p>
						</div>
					</button>
				}
				successSubtitle={<p>You can now make sell high amounts</p>}
				failSubtitle="We couldn’t verify you. Kindly check your details and retry."
			/>
		</div>
	);
};

export default VerificationStatus;
