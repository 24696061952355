import { StepCompleted, StepDefault } from "src/assets/icons/others";
import { useAppContext } from "src/context/AppContext";

type StepType = {
	isComplete: boolean;
	isLast?: boolean;
	text: string;
};

const LCStep = (props: StepType) => {
	const { isPopup } = useAppContext();

	return (
		<div className="relative flex items-center justify-start">
			<div className={`relative space-y-1 flex items-center justify-center flex-col`}>
				<div className={`w-6 h-6 rounded-full`}>{props.isComplete ? <StepCompleted /> : <StepDefault />}</div>
				<p className={`${!isPopup ? "text-xs" : "text-[10px]"} text-center font-semibold text-black py-1 `}>{props.text}</p>
			</div>
			{!props.isLast && (
				<div className="relative h-[57px] w-[24px]">
					<div className={`stepper-link`}></div>
				</div>
			)}
		</div>
	);
};

const LCStepper = (props: { steps: StepType[] }) => {
	return (
		<div className="flex items-center justify-center">
			{props.steps.map((step, i) => (
				<LCStep key={i} {...step} />
			))}
		</div>
	);
};

export default LCStepper;
