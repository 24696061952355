import { useEffect, useState } from "react";
import { useAppContext } from "src/context/AppContext";

const LCSwitchTab = ({
	handleSwitch,
	value,
	tabOne,
	tabTwo,
}: {
	handleSwitch: (v: boolean) => void;
	value: boolean;
	tabOne: string;
	tabTwo: string;
}) => {
	const { colour } = useAppContext();

	const [isOn, setIsOn] = useState(value);

	const handleSwitchClick = (v: boolean) => {
		setIsOn(v);
		handleSwitch(v);
	};

	useEffect(() => {
		setIsOn(value);
	}, [value]);

	return (
		<button
			className="bg-[#F2F2F2] w-52 h-11 mx-auto rounded-full grid grid-cols-2 items-center p-1 text-xs cursor-pointer text-black-20 grotesk"
			onClick={() => handleSwitchClick(!isOn)}
		>
			{isOn}
			<p
				className={`h-full rounded-full flex justify-center items-center ${isOn ? "text-white" : "text-black-40"}`}
				style={{ backgroundColor: isOn ? colour.dark : "" }}
			>
				{tabOne}
			</p>
			<p
				className={`h-full rounded-full flex justify-center items-center font-semibold ${!isOn ? "text-white" : "text-black-40"}`}
				style={{ backgroundColor: !isOn ? colour.dark : "" }}
			>
				{tabTwo}
			</p>
		</button>
	);
};

export default LCSwitchTab;
