import { useEffect } from "react";
import { REFRESH_QUOTES_S } from "../constants/transaction";
import { useCountDown } from "../hooks/useCountdown";
import { useAppContext } from "src/context/AppContext";

const LCQuoteRefreshInfo = ({
	isRefetching,
	refetchFunction,
	spaceBetween,
}: {
	isRefetching: boolean;
	refetchFunction: () => void;
	spaceBetween?: boolean;
}) => {
	const { colour } = useAppContext();

	const [counter, start] = useCountDown(REFRESH_QUOTES_S);

	useEffect(() => {
		start();
	}, [start]);

	useEffect(() => {
		if (counter === 1) {
			refetchFunction();
		}
	}, [counter, refetchFunction]);

	if (spaceBetween)
		return (
			<p className="text-xs font-medium" style={{ color: colour.dark }}>
				{counter}s
			</p>
		);

	return (
		<div className="flex items-center justify-end py-1 space-x-1" style={{ color: colour.dark }}>
			<div className="flex items-center justify-between py-1 space-x-1">
				<i className={`ri-history-fill ri-1x ${isRefetching && "animate-reverse-spin"}`} style={{ color: colour.dark }}></i>
				<p className="text-xs font-medium" style={{ color: colour.dark }}>
					{isRefetching ? `Fetching Rate...` : `Rate refreshes in ${counter}s`}
				</p>
			</div>
		</div>
	);
};

export default LCQuoteRefreshInfo;
