import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { sendMessageToParent } from "src/utility";
import { useAppContext } from "./AppContext";
import LCWidgetContainer from "src/components/LCWidgetContainer";
import ErrorBoundary from "src/screens/ErrorBoundary";
import { useQuery } from "@tanstack/react-query";
import { getCheckOutDetails, getTransactionInfo } from "src/api";
import LCScreenLoader from "src/components/LCScreenLoader";
import TransactionWarning from "src/screens/TransactionWarning";
import { getJsonFromStorage, setInStorage, setJsonInStorage } from "src/utility/storage";
import {
	ENTER_EMAIL,
	ENTER_VERIFICATION_CODE,
	INITIATE_PURCHASE,
	ORDER_INFO,
	SELECT_RECEIPT_METHOD,
	SEND_TO_WALLET_ADDRESS,
	TRANSACTION_WARNING,
} from "src/constants/screen";
import { TRANSACTION_STATE } from "src/constants/transaction";
import chroma from "chroma-js";
import AppError from "src/screens/AppError";

const TransactionContext = createContext<{
	info: any;
	submittedDetails: any;
	transactionRef: string;
	setInfo: React.Dispatch<React.SetStateAction<any>>;
	setSubmittedDetails: React.Dispatch<React.SetStateAction<any>>;
}>({
	info: {},
	transactionRef: "",
	setInfo: () => {},
	submittedDetails: {},
	setSubmittedDetails: () => {},
});

interface IComponentToRender {
	children: JSX.Element;
	checkoutLinkRef: string;
	isCheckOutLoading: boolean;
	isTransactionLoading: boolean;
	checkoutError: boolean;
	transactionError: boolean;
}

const TraansactionContextProvider = ({ children }: { children: JSX.Element }) => {
	const location = useLocation();
	const { setErrorMessage, errorMessage, toastMessage, setToastMessage, setCurrentScreen, currentScreen, setAppColour, setIsPopup, isPopup } =
		useAppContext();

	const [transactionRef, setTransactionRef] = useState("");
	const [submittedDetails, setSubmittedDetails] = useState(getJsonFromStorage("submittedDetails") || {});
	const [transactionInfo, setTransactionInfo] = useState<any>({});
	const [checkoutLinkRef, setCheckoutLinkRef] = useState(location.pathname.substring(1));

	const { isLoading: isCheckOutLoading, isError: checkoutError } = useQuery({
		queryKey: ["checkoutDetails", checkoutLinkRef],
		queryFn: () => getCheckOutDetails(checkoutLinkRef),
		enabled: !!checkoutLinkRef,
		onSuccess: (data) => {
			setTransactionRef(data.data.reference);
			setInStorage("key", data.data.public_key);
		},
	});

	const {
		isLoading: isTransactionLoading,
		isError: transactionError,
		refetch,
	} = useQuery({
		queryKey: ["transaction-info", transactionRef],
		queryFn: () => getTransactionInfo(transactionRef),
		enabled: !!transactionRef,
		onSuccess: (data) => {
			if (data.data.widget_color) {
				const color = data.data.widget_color;
				setAppColour({
					default: color,
					dark: chroma(color).darken(1.2).hex(),
					darker: chroma(color).darken(2).hex(),
					light: chroma(color).alpha(0.1).hex(),
					disabled: chroma(color).alpha(0.7).hex(),
				});
			}
			setTransactionInfo(data.data);
		},
	});

	useEffect(() => {
		sendMessageToParent({ key: "sell-confirmIAmAPopup" });
	}, []);

	useEffect(() => {
		setJsonInStorage("submittedDetails", submittedDetails);
	}, [submittedDetails]);

	useEffect(() => {
		if (!errorMessage?.trim().length || errorMessage === "Unable to fetch transaction, kindly refresh") return;
		const timeout = setTimeout(() => {
			setErrorMessage("");
		}, 3000);

		return () => {
			clearTimeout(timeout);
		};
	}, [errorMessage, setErrorMessage]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setToastMessage("");
		}, 2000);

		return () => {
			clearTimeout(timeout);
		};
	}, [setToastMessage, toastMessage]);

	// if transaction is abandoned navigate to transaction warning screen
	useEffect(() => {
		if (transactionInfo.is_abandoned) {
			setCurrentScreen(TRANSACTION_WARNING);
			return;
		}
		if (
			transactionInfo.state === TRANSACTION_STATE.PENDING &&
			[INITIATE_PURCHASE, ENTER_EMAIL, ENTER_VERIFICATION_CODE, SELECT_RECEIPT_METHOD].includes(currentScreen)
		) {
			setCurrentScreen(SEND_TO_WALLET_ADDRESS);
			return;
		}
		if (
			transactionInfo.state === TRANSACTION_STATE.INITIATED &&
			!!transactionInfo?.hasOwnProperty("destination") &&
			[INITIATE_PURCHASE, ENTER_EMAIL, ENTER_VERIFICATION_CODE, SELECT_RECEIPT_METHOD].includes(currentScreen)
		) {
			setCurrentScreen(SEND_TO_WALLET_ADDRESS);
			return;
		}
		if (transactionInfo.state === TRANSACTION_STATE.PROCESSING || transactionInfo.state === TRANSACTION_STATE.COMPLETED) {
			setCurrentScreen(ORDER_INFO);
			return;
		}
	}, [currentScreen, setCurrentScreen, transactionInfo]);

	useEffect(() => {
		refetch();
	}, [currentScreen, refetch]);

	useEffect(() => {
		sendMessageToParent({ key: "sell-confirmIAmAPopup" });
	}, []);

	useEffect(() => {
		function getMessageFromParent(event: MessageEvent<any>) {
			//  const origin = event.origin;
			if (typeof event.data == "object") {
				if (event.data.nature === "popup") {
					if (!isPopup) {
						setIsPopup(true);
					}
				}
				if (event.data.call === "sendError") {
					if (event.data.value) {
						setErrorMessage(event.data.value);
					}
				}
				if (event.data.call === "sendCheckoutRef") {
					setCheckoutLinkRef(event.data.value);
				}
			}
		}
		window.addEventListener("message", getMessageFromParent);

		return () => {
			window.removeEventListener("message", getMessageFromParent);
		};
	}, [isPopup, setErrorMessage, setIsPopup]);

	return (
		<TransactionContext.Provider
			value={{
				info: transactionInfo,
				setInfo: setTransactionInfo,
				submittedDetails,
				setSubmittedDetails,
				transactionRef,
			}}
		>
			<LCWidgetContainer transaction={transactionInfo} transactionRef={transactionRef}>
				<ErrorBoundary>
					<ComponentToRender
						checkoutError={checkoutError}
						checkoutLinkRef={checkoutLinkRef}
						isCheckOutLoading={isCheckOutLoading}
						isTransactionLoading={isTransactionLoading}
						transactionError={transactionError}
					>
						{children}
					</ComponentToRender>
				</ErrorBoundary>
			</LCWidgetContainer>
		</TransactionContext.Provider>
	);
};

const ComponentToRender = ({
	children,
	checkoutLinkRef,
	isCheckOutLoading,
	isTransactionLoading,
	checkoutError,
	transactionError,
}: IComponentToRender) => {
	if (!checkoutLinkRef) return <AppError />;

	if (isCheckOutLoading || isTransactionLoading) return <LCScreenLoader />;

	if (checkoutError || transactionError) return <TransactionWarning errorMessage="Couldn't initialize transaction" handleRetry={undefined} />;

	return children;
};

export default TraansactionContextProvider;

export const useTransactionContext = () => useContext(TransactionContext);
