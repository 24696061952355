export const INITIATE_PURCHASE = "initiate_purchase";
export const ENTER_EMAIL = "enter_email";
export const ENTER_VERIFICATION_CODE = "enter_verification_code";
export const ENTER_WALLET_ADDRESS = "enter_wallet_address";
export const SELECT_RECEIPT_METHOD = "select_receipt_method";
export const RECEIVE_WITH_TRANSFER = "receive_with_transfer";
export const RECEIVE_WITH_MOBILE = "receive_with_mobile";;
export const ORDER_INFO = "order_info";
export const TRANSACTION_HISTORY = "transaction_history";
export const SEND_TO_WALLET_ADDRESS = "send_to_wallet_address";

export const ID_VERIFICATION = "id_verification";
export const VERIFY_WITH_BVN = "verify_with_bvn";
export const VERIFY_ID_VER_CODE = "verify_id_ver_code";
export const VERIFY_WITH_NIN = "verify_with_nin";
export const VERIFY_WITH_GOV_ISSUED_ID = "verify_with_gov_issued_id";
export const VERIFICATION_STATUS = "verification_status";
export const VERIFICATION_STATUS_SUCCESS = "verification_status_success";
export const VERIFICATION_STATUS_FAILED = "verification_status_failed";
export const PAYMENT_STATUS = "payment_status";

export const TRANSACTION_WARNING = "transaction_warning";
export const APP_ERROR = "app_error";
