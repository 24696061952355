import { create } from "zustand";
import { STORAGE_CHECKOUT_DETAILS, STORAGE_TOKEN } from "./constants/storage";
import AppContextProvider from "./context/AppContext";
import TraansactionContextProvider from "./context/TransactionContext";
import ScreenManager from "./screens";
import "./styles/global.css";
import "./styles/typography.css";
import "remixicon/fonts/remixicon.css";
import { clearFromSession } from "./utility/storage";
import { useEffect } from "react";

type TState = {
	[STORAGE_CHECKOUT_DETAILS]: any;
	[STORAGE_TOKEN]: any;
	[x: string]: any;
};
type TAction = {
	setCheckoutDetails: (details: any) => void;
	setToken: (details: any) => void;
	reset: () => void;
};

export const appContextStore = create<TState & TAction>((set) => ({
	[STORAGE_CHECKOUT_DETAILS]: undefined,
	[STORAGE_TOKEN]: undefined,
	setToken: (x) => set(() => ({ [`${STORAGE_TOKEN}:${x.email}`]: x.value })),
	setCheckoutDetails: (x) => set(() => ({ [STORAGE_CHECKOUT_DETAILS]: x })),
	reset: () => set(() => ({})),
}));

function App() {
	useEffect(() => {
		return () => clearFromSession();
	}, []);

	return (
		<AppContextProvider>
			<TraansactionContextProvider>
				<ScreenManager />
			</TraansactionContextProvider>
		</AppContextProvider>
	);
}

export default App;
