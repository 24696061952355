import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import AppError from "./screens/AppError";
import "remixicon/fonts/remixicon.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const APP_ROUTES = createBrowserRouter([
	{
		index: true,
		element: <App />,
		errorElement: <AppError />,
	},
	{
		path: "/:ref",
		element: <App />,
		errorElement: <AppError />,
	},
]);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: false,
		},
	},
});

root.render(
	// <React.StrictMode>
	<QueryClientProvider client={queryClient}>
		<RouterProvider router={APP_ROUTES} />
	</QueryClientProvider>
	// </React.StrictMode>
);
