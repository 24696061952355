import LCNavbar from "../containers/LCNavbar";
import { WarningAlert } from "src/assets/icons/others";

const TransactionWarning = (props: {
  inScreen?: boolean;
  errorMessage?: string;
  handleRetry?: (e: any) => void;
}) => {
  return (
    <div>
      <div className="flex flex-col">
        {props.inScreen ? null : <LCNavbar />}
        <div className="h-[300px] flex items-center justify-center flex-col">
          <div className="space-y-5">
            <div className="w-20 h-20 mx-auto">
              <WarningAlert />
            </div>
            <div className="text-center space-y-2">
              <h2 className="font-semibold text-xl text-black">
                We could not start this transaction
              </h2>
              <p className="w-full mx-auto max-w-[284px] text-sm text-black-40">
                {props.errorMessage ||
                  "It’s either the link is incorrect or the transaction is already completed"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionWarning;
