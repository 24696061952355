import { useEffect, useState } from "react";
import LCButton from "../components/LCButton";
import LCInputAmountDropdown from "../components/LCInputAmountDropdown";
import LCSummary from "../components/LCSummary";
import { ENTER_EMAIL, ENTER_VERIFICATION_CODE, SELECT_RECEIPT_METHOD } from "../constants/screen";
import LCNavbar from "../containers/LCNavbar";
import { before_, calculateReceiverAmount, calculateSenderAmount, formatProcessorFee } from "../utility";
import { useTransactionContext } from "src/context/TransactionContext";
import { useAppContext } from "src/context/AppContext";
import { TSetAmounts, getLimit, getQoute, getSupportedCurrencies, setAmounts } from "src/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import LCScreenLoader from "src/components/LCScreenLoader";

const InitiatePurchase = () => {
	const { setCurrentScreen, colour, isPopup, setErrorMessage } = useAppContext();
	const { info: transactionInfo, transactionRef, setSubmittedDetails } = useTransactionContext();
	const queryClient = useQueryClient();

	const proceedToNextScreen = () => {
		if (transactionInfo.authenticated) {
			return setCurrentScreen(SELECT_RECEIPT_METHOD);
		}
		return setCurrentScreen(isFieldFixed("email") ? ENTER_VERIFICATION_CODE : ENTER_EMAIL);
	};
	const invalidateQuery = async () => {
		await queryClient.removeQueries({ queryKey: ["receipt-method", transactionRef], exact: true });
	};

	useEffect(() => {
		invalidateQuery();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const saveFormInContext = () => {
		setSubmittedDetails((prev: any) => ({
			...prev,
			amountToPay,
			amountToGet,
			currencyToPay,
			currencyToGet,
		}));
	};

	const { mutate, isLoading: isSettingAmount } = useMutation(setAmounts, {
		onSuccess: () => proceedToNextScreen(),
		onError: (error: any) => setErrorMessage(error.message),
	});

	const [fieldError, setFieldError] = useState({ pay: false, get: false });
	const [amountToPay, setAmountToPay] = useState(0);
	const [amountToGet, setAmountToGet] = useState(0);
	const [lastTouched, setLastTouched] = useState<"to_pay" | "to_get" | "">("");
	const [currencyToPay, setCurrencyToPay] = useState({
		chain: "BTC",
		currency: "BTC",
		chain_name: "Bitcoin Network",
		coin_name: "Bitcoin",
		max_decimal: 8,
	});
	const [currencyToGet, setCurrencyToGet] = useState({
		fiat: "NGN",
		name: "Naira",
		country_code: "NG",
		max_decimal: 2,
	});

	const isFieldFixed = (field: string) => transactionInfo.fixed_properties?.includes(field);

	const { data: currencies, isLoading: isCurrencyLoading } = useQuery({
		queryKey: ["currencies"],
		queryFn: () => getSupportedCurrencies(),
	});
	const {
		data: limits,
		isLoading: isLimitLoading,
		refetch: refetchLimits,
		isFetching: isFetchingLimit,
	} = useQuery({
		queryKey: ["limits", currencyToGet?.fiat, currencyToPay.currency],
		queryFn: () => getLimit({ to_currency: currencyToGet?.fiat, from_currency: currencyToPay.currency }),
	});
	const {
		data: quotes,
		isLoading: isQuotesLoading,
		refetch: refetchQuote,
		isFetching: isFetchingQuotes,
	} = useQuery({
		queryKey: ["quotes", currencyToGet?.fiat, currencyToPay.currency],
		queryFn: () => getQoute({ to_currency: currencyToGet?.fiat, from_currency: currencyToPay.currency }),
		enabled: !!limits,
	});

	const handleCurrencyToPayChange = (value: any) => {
		setLastTouched("to_pay");
		setCurrencyToPay(value);
	};
	const handleCurrencyToGetChange = (value: any) => {
		setLastTouched("to_get");
		setCurrencyToGet(value);
	};
	const handleAmountToPayChange = (value: string | undefined) => {
		if (Number(value) === Number(amountToPay)) return;

		setAmountToPay(Number(value) || 0);
		setLastTouched("to_pay");

		if (limits?.data?.from_minimum > Number(value) || limits?.data?.from_maximum < Number(value)) {
			setFieldError((prev) => ({ ...prev, pay: true }));
		} else {
			setFieldError((prev) => ({ ...prev, pay: false }));
		}

		setAmountToGet(
			calculateReceiverAmount({
				senderAmount: Number(value),
				processorFee: formatProcessorFee({ type: quotes?.data?.partner_fee?.type, fee: quotes?.data?.partner_fee?.fee }, Number(value)),
				exchangeRate: Number(quotes?.data?.rate?.amount || 0),
				networkFee: formatProcessorFee({ type: quotes?.data?.fee?.type, fee: quotes?.data?.fee?.amount }, Number(value)),
				dpGet: currencyToPay?.max_decimal,
				dpPay: currencyToGet?.max_decimal,
			}) || 0
		);
	};
	const handleAmountToGetChange = (value: string | undefined) => {
		if (Number(value) === Number(amountToGet)) return;

		setAmountToGet(Number(value) || 0);
		setLastTouched("to_get");

		if (limits?.data?.to_minimum > Number(value) || limits?.data?.to_maximum < Number(value)) {
			setFieldError((prev) => ({ ...prev, get: true }));
		} else {
			setFieldError((prev) => ({ ...prev, get: false }));
		}

		setAmountToPay(
			calculateSenderAmount({
				receiverAmount: Number(value),
				processorFee: formatProcessorFee({ type: quotes?.data?.partner_fee?.type, fee: quotes?.data?.partner_fee?.fee }, Number(value)),
				exchangeRate: Number(quotes?.data?.rate?.amount),
				networkFee: formatProcessorFee({ type: quotes?.data?.fee?.type, fee: quotes?.data?.fee?.amount }, Number(value)),
				dpGet: currencyToGet?.max_decimal,
				dpPay: currencyToPay?.max_decimal,
			})
		);
	};

	useEffect(() => {
		if (!quotes) return;

		if (transactionInfo?.expected_to_amount) {
			setAmountToGet(transactionInfo?.expected_to_amount);
			setAmountToPay(
				calculateSenderAmount({
					receiverAmount: Number(transactionInfo?.expected_to_amount),
					processorFee: formatProcessorFee(
						{ type: quotes?.data?.partner_fee?.type, fee: quotes?.data?.partner_fee?.fee },
						Number(transactionInfo?.expected_to_amount)
					),
					exchangeRate: Number(quotes?.data?.rate?.amount),
					networkFee: formatProcessorFee(
						{ type: quotes?.data?.fee?.type, fee: quotes?.data?.fee?.amount },
						Number(transactionInfo?.expected_to_amount)
					),
					dpGet: currencyToGet?.max_decimal,
					dpPay: currencyToPay?.max_decimal,
				})
			);
			setLastTouched("to_get");
			return;
		}
		if (transactionInfo?.expected_from_amount) {
			setAmountToPay(transactionInfo?.expected_from_amount);
			setAmountToGet(
				calculateReceiverAmount({
					senderAmount: Number(transactionInfo?.expected_from_amount),
					processorFee: formatProcessorFee(
						{ type: quotes?.data?.partner_fee?.type, fee: quotes?.data?.partner_fee?.fee },
						Number(transactionInfo?.expected_from_amount)
					),
					exchangeRate: Number(quotes?.data?.rate?.amount || 0),
					networkFee: formatProcessorFee(
						{ type: quotes?.data?.fee?.type, fee: quotes?.data?.fee?.amount },
						Number(transactionInfo?.expected_from_amount)
					),
					dpGet: currencyToPay?.max_decimal,
					dpPay: currencyToGet?.max_decimal,
				}) || 0
			);
			setLastTouched("to_pay");
		} else {
			setAmountToPay(Number(limits?.data?.from_minimum));
			setAmountToGet(
				calculateReceiverAmount({
					senderAmount: Number(Number(limits?.data?.from_minimum)),
					processorFee: formatProcessorFee(
						{ type: quotes?.data?.partner_fee?.type, fee: quotes?.data?.partner_fee?.fee },
						Number(amountToPay)
					),
					exchangeRate: Number(quotes?.data?.rate?.amount || 0),
					networkFee: formatProcessorFee({ type: quotes?.data?.fee?.type, fee: quotes?.data?.fee?.amount }, Number(amountToPay)),
					dpGet: currencyToPay?.max_decimal,
					dpPay: currencyToGet?.max_decimal,
				}) || 0
			);
			setLastTouched("to_pay");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transactionInfo, quotes]);

	useEffect(() => {
		const toPay = currencies?.data?.sender_currency.find(
			(currency: any) => currency.chain === transactionInfo.crypto_chain && currency.currency === transactionInfo.from_currency
		);
		const toGet = currencies?.data?.receiver_currency.find((currency: any) => currency.fiat === transactionInfo.to_currency);
		if (toPay) {
			setCurrencyToPay(toPay);
		}
		if (toGet) {
			setCurrencyToGet(toGet);
		}
	}, [transactionInfo, currencies]);

	const areFieldsValid = () => {
		const hasFormError = { pay: false, get: false };
		if (Number(limits?.data?.from_minimum) > Number(amountToPay) || Number(limits?.data?.from_maximum) < Number(amountToPay)) {
			hasFormError.pay = true;
		}
		if (
			(Number(limits?.data?.to_minimum) > Number(amountToGet) || Number(limits?.data?.to_maximum) < Number(amountToGet)) &&
			lastTouched === "to_get"
		) {
			hasFormError.get = true;
		}

		setFieldError(hasFormError);

		return !Object.values(hasFormError).some((value) => !!value);
	};

	const handleContinueClick = () => {
		const isValid = areFieldsValid();
		if (!isValid) return;
		saveFormInContext();
		const data: TSetAmounts = {
			to_amount: amountToGet,
			from_amount: amountToPay,
			reference: transactionRef,
			from_currency: currencyToPay.currency,
			crypto_chain: currencyToPay.chain,
			to_currency: currencyToGet.fiat,
			country_code: currencyToGet.country_code,
		};
		if (isFieldFixed("from_amount") || isFieldFixed("to_amount")) {
			delete data.from_amount;
			delete data.to_amount;
		}
		if (lastTouched === "to_get") {
			delete data.from_amount;
		}
		if (lastTouched === "to_pay") {
			delete data.to_amount;
		}

		if (isFieldFixed("from_currency")) delete data.from_currency;

		if (isFieldFixed("crypto_chain")) delete data.crypto_chain;

		if (isFieldFixed("to_currency")) delete data.to_currency;

		if (isFieldFixed("country_code")) delete data.country_code;

		mutate(data);
	};

	if (isLimitLoading || isQuotesLoading || isCurrencyLoading) return <LCScreenLoader />;

	return (
		<div className="pb-3">
			<LCNavbar hasCompanyName />
			<div className="mb-4">
				<div className={`flex flex-col w-full ${!isPopup ? "space-y-4" : "space-y-2"} items-center mb-2`}>
					<LCInputAmountDropdown
						label="You pay"
						isSearchable
						value={Math.max(amountToPay, 0)}
						onChange={handleAmountToPayChange}
						onOptionChange={handleCurrencyToPayChange}
						selectedOption={currencyToPay}
						defaultOption={currencyToPay}
						dropDownData={currencies?.data?.sender_currency}
						placeholder={"0.0"}
						dropdownTitle={"Select Currency"}
						searchPlaceholder="Select coin"
						decimalsLimit={currencyToPay?.max_decimal || 0}
						isCurrencyLoading={isCurrencyLoading}
						minValue={Number(limits?.data?.from_minimum || 0)}
						maxValue={Number(limits?.data?.from_maximum || 0)}
						hasError={fieldError.pay}
						isAllDisabled={isFieldFixed("from_amount") || isFieldFixed("to_amount")}
						isDropdownDisabled={isFieldFixed("from_currency")}
						extraInfo={currencyToPay.chain_name}
					/>

					<LCInputAmountDropdown
						label="You get"
						isSearchable
						value={Math.max(amountToGet, 0)}
						onChange={handleAmountToGetChange}
						onOptionChange={handleCurrencyToGetChange}
						selectedOption={currencyToGet}
						defaultOption={currencyToGet}
						dropDownData={currencies?.data?.receiver_currency}
						placeholder={"0.0"}
						dropdownTitle={"Select Currency"}
						searchPlaceholder="Select currency"
						decimalsLimit={currencyToGet?.max_decimal || 0}
						decimalScale={2}
						isCurrencyLoading={isCurrencyLoading}
						minValue={Number(limits?.data?.to_minimum || 0)}
						maxValue={Number(limits?.data?.to_maximum || 0)}
						hasError={fieldError.get}
						isAllDisabled={isFieldFixed("from_amount") || isFieldFixed("to_amount")}
						isDropdownDisabled={isFieldFixed("to_currency")}
					/>
					<LCSummary
						amountToPay={Math.max(amountToPay, 0) || 0}
						amountToGet={Math.max(amountToGet, 0) || 0}
						currencyToPay={before_(currencyToPay?.currency)}
						currencyToGet={currencyToGet.fiat}
						isRefetching={isFetchingQuotes || isFetchingLimit}
						refetchFunction={() => {
							refetchQuote();
							refetchLimits();
						}}
						quote={quotes?.data}
					/>
				</div>
				<LCButton type="submit" text="Continue" onClick={handleContinueClick} isLoading={isSettingAmount} />
			</div>
			<p className="text-xs font-medium text-center text-black-40">
				By continuing you agree to our{" "}
				<a href="https://localramp.co/terms-of-use" target="_blank" rel="noreferrer">
					<span style={{ color: colour.dark }}> terms of use</span>
				</a>
			</p>
		</div>
	);
};

export default InitiatePurchase;
