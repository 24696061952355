import { useMutation } from "@tanstack/react-query";
import chroma from "chroma-js";
import { useEffect, useState } from "react";
import { setDestination, verifyBankAccount } from "src/api";
import LCButton from "src/components/LCButton";
import LCDropdown from "src/components/LCDropdownNormal";
import LCInput from "src/components/LCInput";
import { INITIATE_PURCHASE, SEND_TO_WALLET_ADDRESS } from "src/constants/screen";
import LCNavbar from "src/containers/LCNavbar";
import { useAppContext } from "src/context/AppContext";
import { useTransactionContext } from "src/context/TransactionContext";

interface TReceiveWithTransferProps {
	options: any[];
}
interface TRenderAccountNameProps {
	bank: string;
	accountNumber: string;
	setIsValidAccount: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReceiveWithTransfer = ({ options }: TReceiveWithTransferProps) => {
	const { setCurrentScreen, setErrorMessage, colour } = useAppContext();
	const { transactionRef } = useTransactionContext();

	const { mutate, isLoading } = useMutation(setDestination, {
		onSuccess: async () => setCurrentScreen(SEND_TO_WALLET_ADDRESS),
		onError: (error: any) => setErrorMessage(error.message),
	});

	const [bank, setBank] = useState("");
	const [accountNumber, setAccountNumber] = useState("");
	const [isValidAccount, setIsValidAccount] = useState(false);

	const handleContinueClick = () =>
		mutate({ reference: transactionRef, destination_type: "bank_account", account_number: accountNumber, bank_code: bank });
	const handleBackClick = () => setCurrentScreen(INITIATE_PURCHASE);

	return (
		<div className="flex flex-col">
			<LCNavbar hasBack handleBackClick={handleBackClick} />
			<div className="flex flex-col gap-6">
				<div className="flex items-center justify-between w-full pt-2">
					<h1 className="text-base font-semibold text-black">Payment Method</h1>
					<p
						className="py-[4px] px-[8px] rounded text-[10px] font-medium"
						style={{ color: colour.default, backgroundColor: chroma(colour.default).alpha(0.2).hex() }}
					>
						Bank Transfer
					</p>
				</div>
				<LCDropdown
					onOptionChange={(value) => setBank(value.code)}
					dropdownTitle={"Bank Name"}
					dropdownPlaceholder={"Select bank"}
					options={options}
					isDisabled={false}
					isSearchable={true}
				/>
				<LCInput
					type="number"
					label="Account Number"
					placeholder="01234567890"
					value={accountNumber}
					onChange={(e) => {
						setAccountNumber(e.target.value);
					}}
				/>
				<RenderAccountName bank={bank} accountNumber={accountNumber} setIsValidAccount={setIsValidAccount} />
				<LCButton type="submit" text="Continue" onClick={handleContinueClick} isDisabled={!isValidAccount} isLoading={isLoading} />
			</div>
		</div>
	);
};

const RenderAccountName = ({ bank, accountNumber, setIsValidAccount }: TRenderAccountNameProps) => {
	const { colour } = useAppContext();
	const { info, setSubmittedDetails } = useTransactionContext();

	const { mutate, isLoading, isError, data } = useMutation(verifyBankAccount, {
		onSuccess: (data) => {
			setIsValidAccount(true);
			setSubmittedDetails((prevState: any) => ({ ...prevState, delivery_to: data?.data?.account_name }));
		},
	});

	useEffect(() => {
		setIsValidAccount(false);
		if (bank && accountNumber.length === 10) {
			mutate({ currency: info.to_currency, bank_code: bank, account_number: accountNumber });
		}
	}, [bank, accountNumber, mutate, setIsValidAccount, info.to_currency]);

	if (!bank || accountNumber.length !== 10) return null;

	if (bank && accountNumber.length === 10 && isError)
		return (
			<div style={{ backgroundColor: chroma(colour.default).alpha(0.2).hex() }} className="px-2 py-2 rounded">
				<p className="text-sm font-medium text-red">Error fetching account details</p>
			</div>
		);

	if (isLoading)
		return (
			<div style={{ backgroundColor: chroma(colour.default).alpha(0.2).hex() }} className="px-2 py-2 rounded">
				<p className="text-sm font-medium" style={{ color: colour.darker }}>
					Fetching acount details...
				</p>
			</div>
		);

	return (
		<div style={{ backgroundColor: chroma(colour.default).alpha(0.2).hex() }} className="px-2 py-2 rounded">
			<p className="text-sm font-medium" style={{ color: colour.darker }}>
				{data?.data?.account_name}
			</p>
		</div>
	);
};

export default ReceiveWithTransfer;
