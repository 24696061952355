// import { useState } from "react";
import {
	ENTER_VERIFICATION_CODE,
	ID_VERIFICATION,
	INITIATE_PURCHASE,
	ORDER_INFO,
	TRANSACTION_HISTORY,
	VERIFY_WITH_BVN,
	VERIFY_WITH_GOV_ISSUED_ID,
	VERIFY_WITH_NIN,
	TRANSACTION_WARNING,
	VERIFY_ID_VER_CODE,
	APP_ERROR,
	ENTER_EMAIL,
	SELECT_RECEIPT_METHOD,
	SEND_TO_WALLET_ADDRESS,
	VERIFICATION_STATUS_SUCCESS,
	VERIFICATION_STATUS_FAILED,
	VERIFICATION_STATUS,
} from "../constants/screen";
import TransactionWarning from "./TransactionWarning";
import AppError from "./AppError";
import { useAppContext } from "src/context/AppContext";
import InitiatePurchase from "./InitiatePurchase";
import EnterEmail from "./EnterEmail";
import { useState } from "react";
import EnterVerificationCode from "./EnterVerificationCode";
import SelectReceiptMethod from "./SelectReceiptMethod";
import TransactionHistory from "./TransactionHistory";
import OrderInfo from "./OrderInfo";
import IDVerification from "./IDVerification";
import VerifyWithBVN from "./VerifyWithBVN";
import VerifyWithNIN from "./VerifyWithNIN";
import VerifyWithGovIssuedID from "./VerifyWithGovIssuedID";
import VerificationStatus from "./VerificationStatus";
import VerifyIDVerificationCode from "./VerifyIDVerificationCode";
import SendtoWalletAddress from "./SendtoWallet";

const ScreenManager = () => {
	const { currentScreen, setCurrentScreen } = useAppContext();

	const [phoneNumberSubmitted, setPhoneNumberSubmitted] = useState("");
	const [emailSubmitted, setEmailSubmitted] = useState("");
	const [bvnSubmitted, setBvnSubmitted] = useState("");
	const [ninSubmitted, setNinSubmitted] = useState("");

	switch (currentScreen) {
		case INITIATE_PURCHASE:
			return <InitiatePurchase />;
		case ENTER_EMAIL:
			return <EnterEmail setEmailSubmitted={setEmailSubmitted} />;
		case ENTER_VERIFICATION_CODE:
			return <EnterVerificationCode emailSubmitted={emailSubmitted} />;

		case TRANSACTION_WARNING:
			return <TransactionWarning />;

		case SELECT_RECEIPT_METHOD:
			return <SelectReceiptMethod />;

		case SEND_TO_WALLET_ADDRESS:
			return <SendtoWalletAddress />;

		case ID_VERIFICATION:
			return <IDVerification />;
		case VERIFY_WITH_BVN:
			return <VerifyWithBVN handleSubmittedPhoneNumber={setPhoneNumberSubmitted} handleSubmittedBVN={setBvnSubmitted} />;
		case VERIFY_WITH_NIN:
			return <VerifyWithNIN handleSubmittedNIN={setNinSubmitted} handleReturnedPhoneNumber={setPhoneNumberSubmitted} />;
		case VERIFY_WITH_GOV_ISSUED_ID:
			return <VerifyWithGovIssuedID />;
		case VERIFY_ID_VER_CODE:
			return <VerifyIDVerificationCode ninSubmitted={ninSubmitted} bvnSubmitted={bvnSubmitted} idPhoneNumberSubmitted={phoneNumberSubmitted} />;
		case VERIFICATION_STATUS:
			return <VerificationStatus />;
		case VERIFICATION_STATUS_SUCCESS:
			return <VerificationStatus state />;
		case VERIFICATION_STATUS_FAILED:
			return <VerificationStatus state={false} />;

		case ORDER_INFO:
			return <OrderInfo />;

		case TRANSACTION_HISTORY:
			return <TransactionHistory handleHomeClick={() => setCurrentScreen(INITIATE_PURCHASE)} />;

		case APP_ERROR:
			return <AppError />;
		default:
			return <InitiatePurchase />;
	}
};

export default ScreenManager;
