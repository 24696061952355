import { MouseEventHandler } from "react";
import { useAppContext } from "src/context/AppContext";
interface ILCButton {
	text: string;
	type?: "button" | "submit" | "reset" | undefined;
	isDisabled?: boolean;
	isLoading?: boolean;
	isGray?: boolean;
	onClick: MouseEventHandler<Element>;
}
const LCButton = ({ text, type, onClick, isDisabled = false, isLoading = false, isGray = false }: ILCButton) => {
	const { colour } = useAppContext();

	return (
		<button
			type={type || "button"}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			disabled={isDisabled || isLoading}
			className={`w-full p-[10px] text-sm rounded-[4px] ${
				isGray ? "text-black-80 font-semibold" : isDisabled || isLoading ? "cursor-not-allowed text-white" : "text-white"
			} `}
			style={{
				backgroundColor: isGray ? "#F2F2F2" : isDisabled || isLoading ? colour.disabled : colour.default,
			}}
		>
			<div className="flex items-center justify-center">
				<span>{text}</span>
				{isLoading ? (
					<span className="ml-2">
						<div className="animate-spin">
							<i className="block text-white ri-loader-4-line" />
						</div>
					</span>
				) : null}
			</div>
		</button>
	);
};

export default LCButton;
