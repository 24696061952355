import { useQuery } from "@tanstack/react-query";
import chroma from "chroma-js";
import { useEffect } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { requiresVerification as checkVerification, getDepositAddress, getTransactionInfo, obtainQuote } from "src/api";
import LCButton from "src/components/LCButton";
import LCQuoteRefreshInfo from "src/components/LCQuoteRefreshInfo";
import LCScreenLoader from "src/components/LCScreenLoader";
import { ID_VERIFICATION, ORDER_INFO, TRANSACTION_WARNING } from "src/constants/screen";
import { TRANSACTION_STATE } from "src/constants/transaction";
import { useAppContext } from "src/context/AppContext";
import { useTransactionContext } from "src/context/TransactionContext";
import { calculateReceiverAmount, commalize, copyToClipboard, formatProcessorFee, maskWalletAddress } from "src/utility";

const SendtoWalletAddress = () => {
	const { setCurrentScreen, setToastMessage, colour } = useAppContext();
	const { transactionRef, setInfo, info } = useTransactionContext();

	const { data: verificationStatus, isLoading: isVerificationStatusLoading } = useQuery({
		queryKey: ["requires-verification", transactionRef],
		queryFn: () => checkVerification(transactionRef),
	});
	const requiresVerification = verificationStatus?.data?.requires_verification;

	const { data, isFetching: isAddressLoading } = useQuery({
		queryKey: ["deposit-address", transactionRef],
		queryFn: () => getDepositAddress(transactionRef),
		enabled: !isVerificationStatusLoading && !requiresVerification,
	});

	const { data: polledTransaction } = useQuery({
		queryKey: ["poll-transaction-info", transactionRef],
		queryFn: () => getTransactionInfo(transactionRef),
		refetchInterval: 10000,
	});

	const depositAddress = data?.data;

	const handlePaymentMadeClick = () => setCurrentScreen(ORDER_INFO);

	useEffect(() => {
		if (polledTransaction?.data?.state === TRANSACTION_STATE.PROCESSING || polledTransaction?.data?.state === TRANSACTION_STATE.COMPLETED) {
			setInfo(polledTransaction.data);
			setCurrentScreen(ORDER_INFO);
			return;
		}
		if (polledTransaction?.data?.is_abandoned) {
			setCurrentScreen(TRANSACTION_WARNING);
			return;
		}
	}, [polledTransaction, setCurrentScreen, setInfo]);

	if (isVerificationStatusLoading || isAddressLoading) return <LCScreenLoader info="Just a second..." />;

	if (requiresVerification) {
		setCurrentScreen(ID_VERIFICATION);
		return null;
	}

	return (
		<div className="flex flex-col text-center">
			<Countdown
				date={depositAddress?.address_timeout}
				renderer={({ minutes, seconds }) => {
					return (
						<div className="self-end text-base font-semibold" style={{ color: colour.default }}>
							{zeroPad(minutes)}:{zeroPad(seconds)}
						</div>
					);
				}}
				onComplete={() => setCurrentScreen(TRANSACTION_WARNING)}
			/>
			<p className="w-full px-3 py-2 text-sm grotesk">
				Send {depositAddress.amount} {depositAddress.currency} to the wallet address below{" "}
				<span className="font-bold text-[#875100]">ONCE.</span>
			</p>
			<p className="text-xs grotesk py-2 px-3 w-full bg-[#FFF7EB] rounded-lg text-[#875100]">
				This is a <span className="font-bold">{depositAddress.chain_name}</span> address
			</p>
			<img src={depositAddress.qr_code} alt="qr" className="w-[40%] mx-auto ct-square" />
			<div className="flex items-center justify-start gap-2 mx-auto mt-2" style={{ color: colour.default }}>
				<p className="text-sm font-bold max-w-[200px]">{maskWalletAddress(depositAddress.address)}</p>
				<button
					className="outline-none cursor-pointer"
					onClick={() => copyToClipboard(depositAddress.address).then(() => setToastMessage("Copied!"))}
				>
					<i className="ri-file-copy-fill ri-lg" />
				</button>
			</div>
			<LCQuote amountToPay={depositAddress.amount} currencyToPay={depositAddress.currency} currencyToGet={info?.to_currency} />
			<p className="w-full px-3 py-2 mt-2 text-xs rounded-lg grotesk">
				Having trouble?{" "}
				<a href="mailto:support@localramp.co" target="_blank" className="font-bold" style={{ color: colour.default }} rel="noreferrer">
					Contact support
				</a>
			</p>
			<LCButton text={"I have made payment"} onClick={handlePaymentMadeClick} />
		</div>
	);
};

const LCQuote = ({ amountToPay, currencyToPay, currencyToGet }: { amountToPay: string; currencyToPay: string; currencyToGet: string }) => {
	const { colour, setToastMessage } = useAppContext();
	const { info } = useTransactionContext();
	const { data, isFetching, refetch } = useQuery({
		queryKey: ["quote", currencyToPay, info?.to_currency],
		queryFn: () => obtainQuote({ from_currency: currencyToPay, to_currency: info?.to_currency }),
	});

	const amount =
		calculateReceiverAmount({
			senderAmount: Number(amountToPay),
			processorFee: formatProcessorFee({ type: data?.data?.partner_fee?.type, fee: data?.data?.partner_fee?.fee }, Number(amountToPay)),
			exchangeRate: Number(data?.data?.rate?.amount || 0),
			networkFee: formatProcessorFee({ type: data?.data?.fee?.type, fee: data?.data?.fee?.amount }, Number(amountToPay)) || 0,
			dpGet: 2,
			dpPay: 8,
		}) || 0;

	return (
		<div className="mt-3 space-y-1">
			<div className="p-2 space-y-1 text-xs font-semibold rounded-md" style={{ backgroundColor: chroma(colour.default).alpha(0.12).hex() }}>
				<div className="flex items-center justify-between text-black-80">
					<p className="text-black-40">Amount to send</p>
					<p className="flex items-center gap-1">
						{amountToPay} {currencyToPay}{" "}
						<button
							className="outline-none cursor-pointer"
							style={{ color: colour.default }}
							onClick={() => copyToClipboard(amountToPay).then(() => setToastMessage("Copied!"))}
						>
							<i className="ri-file-copy-fill ri-lg" />
						</button>
					</p>
				</div>
				<div className="flex items-center justify-between text-black-80">
					<p className="text-black-40">Recipient to receive</p>
					<p className="">
						~ {currencyToGet} {commalize(amount)}
					</p>
				</div>
				<div className="flex items-center justify-between text-black-80">
					<p className="" style={{ color: colour.default }}>
						Rate refreshes in
					</p>
					<p className="">
						<LCQuoteRefreshInfo isRefetching={isFetching} refetchFunction={refetch} spaceBetween />
					</p>
				</div>
			</div>
		</div>
	);
};

export default SendtoWalletAddress;
