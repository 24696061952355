import { LCClickableList, LCClickableListItem } from "../components/LCClickableList";
import { VERIFY_WITH_BVN, VERIFY_WITH_GOV_ISSUED_ID, VERIFY_WITH_NIN } from "../constants/screen";
import LCNavbar from "../containers/LCNavbar";
import { useAppContext } from "src/context/AppContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { listVerificationType } from "src/api";
import { useTransactionContext } from "src/context/TransactionContext";
import LCScreenLoader from "src/components/LCScreenLoader";
import { useEffect } from "react";

const VERIFICATION_TYPES = {
	NIN: "nin",
	DOCUMENT: "document",
	BVN: "bvn",
};

const IDVerification = () => {
	const { setCurrentScreen } = useAppContext();
	const { info: transactionInfo, transactionRef } = useTransactionContext();
	const queryClient = useQueryClient();

	const { data, isLoading } = useQuery({
		queryKey: ["types"],
		queryFn: () => listVerificationType({ currency: transactionInfo?.to_currency }),
	});

	const verificationMethods = data?.data?.supported_verification_types;

	const invalidateQuery = async () => {
		await queryClient.removeQueries({ queryKey: ["requires-verification", transactionRef], exact: true });
	};

	useEffect(() => {
		invalidateQuery();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isLoading) return <LCScreenLoader />;

	return (
		<div className="flex flex-col">
			<LCNavbar />
			<div className="flex flex-col items-center justify-between w-full">
				<div className="w-full pb-16 text-center">
					<div className="mb-4 space-y-2">
						<h1 className="pb-1 text-lg font-semibold text-black">ID Verification</h1>
						<p className="text-xs py-3 px-3 w-full bg-[#FFF7EB] rounded-lg text-[#875100]">Sale of high amount requires verification</p>
						<p className="text-xs grotesk text-black-80">
							This is a one-off process that will allow you to skip the verification step on any app that supports LocalRamp.
						</p>
					</div>
					{!isLoading ? (
						<LCClickableList>
							{verificationMethods.includes(VERIFICATION_TYPES.BVN) ? (
								<LCClickableListItem
									icon="ri-bank-line"
									title="Verify with BVN"
									handleClick={() => {
										setCurrentScreen(VERIFY_WITH_BVN);
									}}
								/>
							) : null}
							{verificationMethods.includes(VERIFICATION_TYPES.NIN) ? (
								<LCClickableListItem
									icon="ri-profile-line"
									title="Verify with NIN"
									handleClick={() => {
										setCurrentScreen(VERIFY_WITH_NIN);
									}}
								/>
							) : null}
							{verificationMethods.includes(VERIFICATION_TYPES.DOCUMENT) ? (
								<LCClickableListItem
									icon="ri-passport-line"
									title="Provide government issued ID"
									handleClick={() => {
										setCurrentScreen(VERIFY_WITH_GOV_ISSUED_ID);
									}}
								/>
							) : null}
						</LCClickableList>
					) : (
						<LCClickableList>
							<LCClickableListItem
								icon="ri-bank-line"
								title="Verify with BVN"
								handleClick={() => {
									setCurrentScreen(VERIFY_WITH_BVN);
								}}
							/>

							<LCClickableListItem
								icon="ri-profile-line"
								title="Verify with NIN"
								handleClick={() => {
									setCurrentScreen(VERIFY_WITH_NIN);
								}}
							/>

							<LCClickableListItem
								icon="ri-passport-line"
								title="Provide government issued ID"
								handleClick={() => {
									setCurrentScreen(VERIFY_WITH_GOV_ISSUED_ID);
								}}
							/>
						</LCClickableList>
					)}
					<div className="flex items-center justify-center mt-5 space-x-1">
						<i className="ri-timer-2-line ri-lg text-orange"></i>
						<p className="text-sm text-black">
							Takes less than <span className="font-semibold">5 mins</span>{" "}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default IDVerification;
