import { useState } from "react";
import { useAppContext } from "src/context/AppContext";

const LCBurgerMenu = ({ handleClick }: { handleClick: () => void }) => {
	const { isMenuOpen } = useAppContext();
	const [isOpen, setIsOpen] = useState(false);
	const handleToggle = () => {
		setIsOpen(!isOpen);
		handleClick();
	};
	return (
		<button onClick={handleToggle} className="w-5 h-5">
			{isMenuOpen ? (
				<i className="ri-close-fill ri-lg" style={{ color: "black" }} />
			) : (
				<i className="ri-menu-fill ri-lg" style={{ color: "black" }} />
			)}
		</button>
	);
};

export default LCBurgerMenu;
