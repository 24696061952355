import LCBack from "../components/LCBack";
import LCBurgerMenu from "../components/LCBurgerMenu";
import LCNavItem from "../components/LCNavItem";
import { TRANSACTION_HISTORY } from "../constants/screen";
import { getFromStorageLocal } from "../utility/storage";
import { useAppContext } from "src/context/AppContext";
import { useTransactionContext } from "src/context/TransactionContext";

const LCNavbar = ({
	hasBack = false,
	hasCompanyName = false,
	handleBackClick = () => {},
}: {
	hasBack?: boolean;
	handleBackClick?: () => void;
	hasCompanyName?: boolean;
}) => {
	const { setIsMenuOpen, setCurrentScreen, isMenuOpen } = useAppContext();
	const { info } = useTransactionContext();

	const email = info.email;

	const handleLogout = () => {
		localStorage.removeItem(email);
		setIsMenuOpen(!isMenuOpen);
	};

	const handleHelpClick = () => {
		window.location.href = `mailto:support@localramp.co`;
	};

	const isTokenInStorage = () => !!getFromStorageLocal(email);

	return (
		<>
			<div className="w-full">
				<div className={`flex pt-2 mb-3 ${!hasBack && !hasCompanyName ? "justify-end" : "justify-between"} items-center w-full`}>
					{hasBack && <LCBack handleClick={handleBackClick} />}
					{hasCompanyName && <h1 className="text-base font-semibold text-black">{info.merchant_name}</h1>}
					<div className="z-40 float-right">
						<LCBurgerMenu handleClick={() => setIsMenuOpen(!isMenuOpen)} />
					</div>
				</div>
			</div>
			{isMenuOpen && (
				<div className="absolute top-0 right-0 z-30 w-full h-full px-8 bg-white py-7 rounded-3xl">
					<h1 className="mt-1 text-base font-semibold text-black">Hello!</h1>
					<div className="mt-5">
						{isTokenInStorage() ? (
							<LCNavItem
								isActive
								icon="ri-history-fill"
								text="Transaction History"
								onClick={() => setCurrentScreen(TRANSACTION_HISTORY)}
							/>
						) : null}
						<div className="pt-6">
							<LCNavItem icon="ri-question-line" text="Help" onClick={handleHelpClick} />
							{isTokenInStorage() ? (
								<LCNavItem icon="ri-logout-box-r-line" text="Logout" extraText={email} onClick={handleLogout} />
							) : null}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default LCNavbar;
