import axios from "axios";
import { getFromStorage } from "./utility/storage";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    throw new Error(error.response.data.msg || error.response.data.message);
  }
);

api.interceptors.request.use((config) => {
  config.headers["x-auth-token"] = `${getFromStorage("key")}`;
  return config;
}, undefined);

type DestinationDetails = {
  reference: string,
  destination_type: "mobile_money",
  phone_number: string,
  phone_network: string
} | {
  reference: string,
  destination_type: "bank_account",
  account_number: string,
  bank_code: string
}

export type TSetAmounts = {
  from_amount?: number,
  to_amount?: number,
  reference: string,
  from_currency?: string,
  crypto_chain?: string,
  to_currency?: string,
  country_code?: string
}

type VerifyId = {
  reference: string,
  type: "bvn" | "nin" | "document",
  id_number?: string,
  phone_number?: string,
  verification_alert_type?: "whatsapp" | "sms"
}

export const getCheckOutDetails = (checkoutLinkRef: string) => api.get(`/v1/widget/sell/checkout-details/${checkoutLinkRef}`)

export const getTransactionInfo = (reference: string) => api.get(`/v1/widget/sell/info/${reference}`)

export const sendOTP = (data: { reference: string }) => api.post("/v1/widget/sell/email/otp", data)

export const setTransactionEmail = (data: { reference: string, email: string }) => api.post("/v1/widget/sell/email", data)

export const verifyTransactionOTP = (data: { reference: string, otp: string }) => api.put("/v1/widget/sell/email/otp/confirm", data)

export const signInWithToken = (data: { reference: string, user_token: string }) => api.post("/v1/widget/sell/auth-token-login", data)

export const getReceiptMethod = (reference: string) => api.get(`/v1/widget/sell/payout-methods/${reference}`)

export const verifyBankAccount = (data: { currency: string, bank_code: string, account_number: string }) =>
  api.post(`/v1/widget/sell/verify-bank`, data);

export const setDestination = (data: DestinationDetails) => api.put("/v1/widget/sell/destination", data)

export const getLimit = (params: { to_currency: string, from_currency: string }) => api.get("/v1/widget/sell/limits", { params })

export const getQoute = (params: { to_currency: string, from_currency: string }) => api.get("/v1/widget/sell/quote", { params })

export const getSupportedCurrencies = () => api.get("/v1/widget/sell/currencies")

export const requiresVerification = (reference: string) => api.get(`/v1/widget/sell/require-extra-verification/${reference}`)

export const getDepositAddress = (reference: string) => api.get(`/v1/widget/sell/address/${reference}`);

export const obtainQuote = (params: { from_currency: string, to_currency: string }) => api.get("/v1/widget/sell/quote", { params })

export const setAmounts = (data: TSetAmounts) => api.put("/v1/widget/sell/details", data)

export const listVerificationType = (params: { currency: string }) => api.get("v1/widget/sell/identity-verify/methods", { params })

export const verifyID = (data: VerifyId) => api.post("/v1/widget/sell/identity-verify", data)

export const verifyIDOTP = (data: { reference: string, otp: string }) => api.post("/v1/widget/sell/identity-verify/otp", data)

export const getTransactionHistory = (token: string, params: { page?: number | string, limit?: string | number }) => api.post("/v1/widget/sell/history", { user_token: token }, { params })