import { useState } from "react";
import { commalize, formatProcessorFee } from "../utility";
import LCQuoteRefreshInfo from "./LCQuoteRefreshInfo";

interface ILCSummary {
	label?: string;
	amountToGet: number;
	currencyToGet: string | number;
	amountToPay: number;
	currencyToPay: string | number;
	isRefetching: boolean;
	refetchFunction: () => void;
	quote: any;
}
interface ILCQuote {
	amountToGet: number;
	currencyToGet: string | number;
	amountToPay: number;
	currencyToPay: string | number;
	data: any;
}

const LCQuote = ({ amountToGet, amountToPay, currencyToGet, currencyToPay, data }: ILCQuote) => {
	return (
		<div className="space-y-1">
			<div className="space-y-1 text-[10px] font-semibold">
				<div className="flex items-center justify-between text-black-80">
					<p className="text-black-40">Processor Fee</p>
					<p className="">
						{data?.fee?.type === "PERCENT" ? "%" : currencyToGet}{" "}
						{formatProcessorFee({ type: data?.fee?.type, fee: data?.fee?.amount }, Number(amountToPay))}{" "}
					</p>
				</div>
				<div className="flex items-center justify-between text-black-80">
					<p className="text-black-40">Partner Fee</p>
					<p className="">
						{formatProcessorFee({ type: data?.partner_fee?.type, fee: data?.partner_fee?.fee }, Number(amountToPay))}{" "}
						{data?.partner_fee?.type === "PERCENT" ? "%" : currencyToGet}
					</p>
				</div>
				<div className="flex items-center justify-between text-black-80">
					<p className="">
						{amountToPay} {currencyToPay}
					</p>
					<p className="text-black-40">
						{currencyToGet} {commalize(amountToGet)}
					</p>
				</div>
			</div>
		</div>
	);
};

const LCSummary = ({
	label = "Summary",
	amountToGet,
	currencyToGet,
	amountToPay,
	currencyToPay,
	isRefetching,
	quote,
	refetchFunction,
}: ILCSummary) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className="w-full">
			<div className="flex flex-col items-start space-y-1">
				<p className="text-xs font-medium text-black-40">{label}</p>
				<button
					className={`border-b border-b-black-20 w-full text-xs font-semibold pb-2 flex items-center text-left bg-white`}
					onClick={(e) => {
						e.preventDefault();
						setIsOpen(!isOpen);
					}}
				>
					<div className="w-full text-black-40">
						You get{" "}
						<span className="text-black">
							{currencyToGet} {commalize(amountToGet)}
						</span>{" "}
						for{" "}
						<span className="text-black">
							{amountToPay} {currencyToPay}
						</span>
					</div>
					<div className="outline-none">
						{isOpen ? <i className="text-black ri-arrow-up-s-fill"></i> : <i className="text-black ri-arrow-down-s-fill"></i>}
					</div>
				</button>
			</div>
			<LCQuoteRefreshInfo isRefetching={isRefetching} refetchFunction={refetchFunction} />
			{isOpen ? <LCQuote {...{ amountToGet, currencyToGet, amountToPay, currencyToPay }} data={quote} /> : null}
		</div>
	);
};

export default LCSummary;
