import { useEffect, useState } from "react";
import LCButton from "../components/LCButton";
import LCInputPin from "../components/LCInputPin";
import LCScreenLoader from "../components/LCScreenLoader";
import { ENTER_EMAIL, SELECT_RECEIPT_METHOD } from "../constants/screen";
import LCNavbar from "../containers/LCNavbar";
import { maskEmail } from "../utility";
import { useAppContext } from "src/context/AppContext";
import { useTransactionContext } from "src/context/TransactionContext";
import { sendOTP, signInWithToken, verifyTransactionOTP } from "src/api";
import { useMutation } from "@tanstack/react-query";
import { getFromStorageLocal, setInStorageLocal } from "src/utility/storage";
import { useCountDown } from "src/hooks/useCountdown";

const EnterVerificationCode = ({ emailSubmitted }: { emailSubmitted: string }) => {
	const { setCurrentScreen, setErrorMessage } = useAppContext();
	const { transactionRef } = useTransactionContext();

	const { mutate: sendOTPMutate, isLoading: sendOTPLoading } = useMutation(sendOTP, {
		onError: (error: any) => setErrorMessage(error.message),
	});

	const { mutate: signInWithTokenMutate, isLoading: signInWithTokenLoading } = useMutation(signInWithToken, {
		onSuccess: async () => {
			setCurrentScreen(SELECT_RECEIPT_METHOD);
		},
		onError: (error: any) => {
			/// if signin with token fails send otp to email
			sendOTPMutate({ reference: transactionRef });
		},
	});

	const { mutate: verifyOTPMutate, isLoading: verifyOTPLoading } = useMutation(verifyTransactionOTP, {
		onSuccess: async ({ data }) => {
			setInStorageLocal(emailSubmitted, data);
			setCurrentScreen(SELECT_RECEIPT_METHOD);
		},
		onError: (error: any) => setErrorMessage(error.message),
	});

	useEffect(() => {
		// ? check if there is token is localstorage for transaction email
		// ? if there is a token attempt to sign in with token
		// ? else send otp

		const token = getFromStorageLocal(emailSubmitted);

		if (!!token) {
			signInWithTokenMutate({ reference: transactionRef, user_token: token });
			return;
		}
		sendOTPMutate({ reference: transactionRef });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [emailSubmitted]);

	const [verificationCode, setVerificationCode] = useState("");

	const isVerificationCodeValid = () => verificationCode.trim().length === 6;

	const handleContinueClick = () => verifyOTPMutate({ reference: transactionRef, otp: verificationCode });

	const handleBackClick = () => {
		setErrorMessage("");
		setCurrentScreen(ENTER_EMAIL);
	};

	if (sendOTPLoading || signInWithTokenLoading) return <LCScreenLoader />;

	if (verifyOTPLoading) return <LCScreenLoader info="Please hold on while we validate your OTP..." />;

	return (
		<div className="flex flex-col">
			<LCNavbar hasBack handleBackClick={handleBackClick} />
			<form>
				<div className="flex flex-col items-center justify-between w-full">
					<div className="w-full mb-20 text-center">
						<div className="mb-4 space-y-2">
							<h1 className="text-base font-semibold text-black">Verification Code</h1>
							<p className="text-xs text-black-80 w-full max-w-[171px] mx-auto">
								We sent a 6-digit code to <span className="font-bold text-black">{maskEmail(emailSubmitted)}</span>
							</p>
						</div>
						<LCInputPin length={6} onChange={setVerificationCode} />
						<div className="flex items-center justify-center mt-4 space-x-1 text-xs">
							<p className="font-semibold text-black-80">Didn&apos;t receive it?</p>
							<ResendCode setVerificationCode={setVerificationCode} />
						</div>
					</div>
				</div>
				<LCButton
					type="submit"
					isDisabled={!isVerificationCodeValid()}
					isLoading={verifyOTPLoading}
					text="Continue"
					onClick={handleContinueClick}
				/>
			</form>
		</div>
	);
};

const ResendCode = ({ setVerificationCode }: { setVerificationCode: React.Dispatch<React.SetStateAction<string>> }) => {
	const { colour, setErrorMessage } = useAppContext();
	const { transactionRef } = useTransactionContext();
	const [counter, start, , , isCounting] = useCountDown(30);

	useEffect(() => {
		start();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { mutate: resendOTPMutate, isLoading: resendOTPLoading } = useMutation(sendOTP, {
		onError: (error: any) => setErrorMessage(error.message),
	});

	const handleResendCode = () => {
		setVerificationCode("");
		start();
		resendOTPMutate({ reference: transactionRef });
	};

	if (resendOTPLoading)
		return (
			<div>
				<div className="animate-spin">
					<i className="ri-loader-4-line" style={{ color: colour.default }} />
				</div>
			</div>
		);

	if (isCounting)
		return (
			<p className="font-medium" style={{ color: colour.default }}>
				Resend in {counter}s
			</p>
		);

	return (
		<button type="button" onClick={handleResendCode} className="font-medium border-none outline-none" style={{ color: colour.dark }}>
			Resend code
		</button>
	);
};

export default EnterVerificationCode;
