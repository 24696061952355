import { useEffect, useRef, useState } from "react";
import { ID_VERIFICATION, VERIFICATION_STATUS_FAILED, VERIFICATION_STATUS_SUCCESS } from "../constants/screen";
import LCNavbar from "../containers/LCNavbar";
import { copyToClipboard } from "../utility";
import { useAppContext } from "src/context/AppContext";
import { useTransactionContext } from "src/context/TransactionContext";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getTransactionInfo, verifyID } from "src/api";
import { CHECK_FOR_DOCUMENT_STATUS_FREQ } from "src/constants/transaction";

export const DOCUMENT_VERIFICATION_STATE = {
	RECEIVED: "received",
	PASSED: "passed",
	FAILED: "failed",
};

const VerifyWithGovIssuedID = () => {
	const { colour, setToastMessage, setCurrentScreen, setErrorMessage } = useAppContext();

	const { transactionRef, setInfo } = useTransactionContext();

	const handleBack = () => setCurrentScreen(ID_VERIFICATION);

	const [qrData, setQRData] = useState<{ qr: string; link: string } | undefined>();
	const [isDocumentReceived, setIsDocumentReceived] = useState(false);
	const [stop, setStop] = useState(false);
	const [startTimer, setStartTimer] = useState(false);

	const { mutate: verifyIDMutate, isLoading: isPostingData } = useMutation(verifyID, {
		onSuccess: (res: any) => {
			if (res?.data?.qr) {
				setQRData(res?.data);
				setStartTimer(true);
			}
		},
		onError: (error: any) => setErrorMessage(error.message),
	});

	useEffect(() => {
		verifyIDMutate({
			type: "document",
			reference: transactionRef,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const smileIdentityWindow = useRef<Window | null>();

	const openSmileIdentityWindow = () => {
		smileIdentityWindow.current = window.open(qrData?.link);
	};

	const closeSmileIdentityWindow = () => smileIdentityWindow.current?.close();

	useQuery({
		queryKey: ["transactionInfo", transactionRef],
		queryFn: () => getTransactionInfo(transactionRef),
		onSuccess: (res) => {
			if (res?.data) {
				if (res?.data?.document_verification_state === DOCUMENT_VERIFICATION_STATE.RECEIVED) {
					setStartTimer(false);
					setStop(true);
					setIsDocumentReceived(true);
					closeSmileIdentityWindow();
				}
				if (res?.data?.document_verification_state === DOCUMENT_VERIFICATION_STATE.FAILED) {
					setStartTimer(false);
					setStop(true);
					setCurrentScreen(VERIFICATION_STATUS_FAILED);
				}
				if (res?.data?.document_verification_state === DOCUMENT_VERIFICATION_STATE.PASSED) {
					setStartTimer(false);
					setStop(true);
					setInfo(res?.data);
					setCurrentScreen(VERIFICATION_STATUS_SUCCESS);
				}
			}
		},
		onError: (err) => {},
		enabled: startTimer,
		refetchInterval: stop ? false : CHECK_FOR_DOCUMENT_STATUS_FREQ * 1000,
		refetchIntervalInBackground: true,
		refetchOnWindowFocus: false,
	});

	return (
		<div className="flex flex-col pb-3">
			{isDocumentReceived ? (
				<div className="absolute top-0 left-0 z-40 w-full h-full bg-black/80 rounded-2xl">
					<div className="flex flex-col items-center justify-center w-full h-full space-y-4">
						<div className="w-12 h-12 mx-auto animate-spin">
							{/* <img src={WhiteSpinner} alt="spinner" className="w-full h-full" /> */}
						</div>
						<p className="text-lg font-semibold text-center text-white">
							Verifying document. <br /> Do not close modal
						</p>
					</div>
				</div>
			) : null}
			<LCNavbar hasBack handleBackClick={handleBack} />
			<div className="flex flex-col items-center justify-between w-full">
				<div className="w-full text-center">
					<div className="mb-5 space-y-2">
						<h1 className="pb-1 text-lg font-semibold text-black">Government issued ID</h1>
						<p className="text-xs grotesk py-2 px-3 w-full bg-[#FFF7EB] rounded-lg text-[#875100]">
							<span className="font-bold">Please leave this page open during verification</span>
						</p>
						<p className="text-xs grotesk text-black-80">Scan the QR code to continue on phone</p>
					</div>
					<div className="mb-4 space-y-8 text-center">
						<div className="w-24 h-24 mx-auto">
							{!isPostingData ? (
								<img src={qrData?.qr} alt="qr" className="w-full h-full" />
							) : (
								<div className="flex flex-col justify-center w-full h-full item-center">
									<div className="animate-spin">
										<i className="text-gray-600 ri-loader-4-line ri-xl"></i>
									</div>
								</div>
							)}
						</div>
						<p className="text-xs text-black-80">Can’t scan the QR code?</p>
					</div>
					<div className="flex items-center justify-center space-x-2 text-xs">
						<button disabled={isPostingData} onClick={openSmileIdentityWindow}>
							<p className="font-medium" style={{ color: colour.dark }}>
								Continue verification on this device
							</p>
						</button>
						<button
							disabled={isPostingData}
							onClick={() => copyToClipboard(qrData?.link || "").then(() => setToastMessage("Copied!"))}
							className="border border-[#E7E5E5] rounded-[4px] w-6 h-6 flex items-center justify-center outline-none"
						>
							<i className="ri-link" style={{ color: colour.dark }}></i>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default VerifyWithGovIssuedID;
