import { useEffect, useState } from "react";
import LCSwitchTab from "../components/LCSwitchTab";
import LCNavbar from "../containers/LCNavbar";
import { before_, commalize, findCoinLogo } from "../utility";

import LCStepper from "../components/LCStepper";
import LCDescriptionItem from "../components/LCDescriptionItem";
import { TRANSACTION_STATE } from "../constants/transaction";
import { useTransactionContext } from "src/context/TransactionContext";
import { useAppContext } from "src/context/AppContext";
import { useQuery } from "@tanstack/react-query";
import { getTransactionInfo } from "src/api";
import { LoaderSVG } from "src/assets/icons/others";
import { TRANSACTION_WARNING } from "src/constants/screen";

const OrderInfo = () => {
	const { colour, setCurrentScreen } = useAppContext();
	const { transactionRef, info, setInfo, submittedDetails } = useTransactionContext();

	const [shouldShowOrderStatus, setShouldShowOrderStatus] = useState(true);

	const handleHelpClick = () => {
		window.location.href = `mailto:support@localramp.co`;
	};

	const { data: polledTransaction } = useQuery({
		queryKey: ["poll-transaction-info", transactionRef],
		queryFn: () => getTransactionInfo(transactionRef),
		refetchInterval: 10000,
	});

	useEffect(() => {
		if (polledTransaction?.data?.is_abandoned) {
			setCurrentScreen(TRANSACTION_WARNING);
			return;
		}
	}, [polledTransaction, setCurrentScreen, setInfo]);

	return (
		<div className="flex flex-col">
			<LCNavbar />
			<div className="">
				<h1 className="mb-3 text-lg font-medium text-center text-black">
					Your transaction is {polledTransaction?.data?.state === TRANSACTION_STATE.COMPLETED ? "complete" : "pending"}
				</h1>
				<div className="space-y-7">
					<LCSwitchTab handleSwitch={setShouldShowOrderStatus} tabOne="Order status" tabTwo="Order details" value={true} />
					<div className="">
						{shouldShowOrderStatus ? (
							<div className="w-full space-y-7">
								<div className="flex items-center justify-center gap-12 mx-auto">
									<div className="my-6 rounded-full">
										<img src={findCoinLogo({ currency: info?.from_currency })} alt="" className="w-full h-full" />
									</div>
									{polledTransaction?.data?.state !== TRANSACTION_STATE.COMPLETED && (
										<div className="animate-spin">
											<LoaderSVG className="w-14 h-14" />
										</div>
									)}
								</div>
								<div className="mx-auto">
									<LCStepper
										steps={[
											{ isComplete: true, text: "Ordered" },
											{ isComplete: true, text: "Verified" },
											{ isComplete: polledTransaction?.data?.state === TRANSACTION_STATE.PROCESSING, text: "Processed" },
											{
												isComplete: polledTransaction?.data?.state === TRANSACTION_STATE.COMPLETED,
												text: "Delivered",
												isLast: true,
											},
										]}
									/>
								</div>
							</div>
						) : (
							<div className="space-y-4">
								<LCDescriptionItem title="Order ID" content={<p className="text-black-80">{info.order_id}</p>} />
								<LCDescriptionItem
									title="Summary"
									content={
										info?.to_amount ? (
											<p className="text-black-80">
												Recipient to receive ~{submittedDetails?.currencyToGet.fiat} {commalize(Number(info?.to_amount || 0))}
											</p>
										) : (
											"..."
										)
									}
								/>
								<LCDescriptionItem
									title={`${before_(info.to_currency)} will be delivered to`}
									content={
										<div className="flex items-center justify-start space-x-2">
											<p className="text-xs font-bold text-black-80">
												{info?.destination?.account_name || info?.destination?.phone_number}
											</p>
										</div>
									}
								/>
							</div>
						)}
					</div>
				</div>
				<div className="bg-[#F2F2F2] px-4 py-2 rounded-lg flex items-center justify-start gap-[.5rem] w-full mt-[40px]">
					<i className="ri-lightbulb-line ri-lg" style={{ color: colour.default }}></i>
					<p className="text-xs text-black-40">
						You may close this at any time. If there are any issues, we&apos;ll contact you via email. For more help{" "}
						<button onClick={handleHelpClick} className="font-semibold outline-none" style={{ color: colour.default }}>
							click here.
						</button>
					</p>
				</div>
			</div>
		</div>
	);
};

export default OrderInfo;
