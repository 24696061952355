import LCScreenLoader from "../components/LCScreenLoader";
import { useQuery } from "@tanstack/react-query";
import { useTransactionContext } from "src/context/TransactionContext";
import { getReceiptMethod } from "src/api";
import ReceiveWithTransfer from "./ReceiveWithTransfer";
import ReceiveWithMobile from "./ReceiveWithMobile";
import TransactionWarning from "./TransactionWarning";

const SelectReceiptMethod = () => {
	const { transactionRef } = useTransactionContext();

	const { data, isLoading: isMethodLoading } = useQuery({
		queryKey: ["receipt-method", transactionRef],
		queryFn: () => getReceiptMethod(transactionRef),
	});

	const method = data?.data?.destination_type;
	const list = data?.data?.list;

	if (isMethodLoading) return <LCScreenLoader info="Just a second..." />;

	if (method === "bank_transfer") {
		return <ReceiveWithTransfer options={list} />;
	} else if (method === "mobile_money") {
		return <ReceiveWithMobile options={list} />;
	} else {
		return <TransactionWarning errorMessage="Couldn't get a method of payment for this transaction" />;
	}
};

export default SelectReceiptMethod;
