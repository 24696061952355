import { useState } from "react";
import LCButton from "../components/LCButton";
import LCInput from "../components/LCInput";
import { ID_VERIFICATION } from "../constants/screen";
import LCNavbar from "../containers/LCNavbar";
import { useAppContext } from "src/context/AppContext";
import { useMutation } from "@tanstack/react-query";
import { verifyID } from "src/api";
import { useTransactionContext } from "src/context/TransactionContext";

const VerifyWithNIN = ({
	handleSubmittedNIN,
	handleReturnedPhoneNumber,
}: {
	handleSubmittedNIN: (v: string) => void;
	handleReturnedPhoneNumber: (v: string) => void;
}) => {
	const { setCurrentScreen, setErrorMessage } = useAppContext();

	const { transactionRef } = useTransactionContext();

	const [nin, setNin] = useState("");

	const handleBack = () => setCurrentScreen(ID_VERIFICATION);

	const { mutate: verifyIDMutate, isLoading: isSubmittingNIN } = useMutation(verifyID, {
		onError: (error: any) => setErrorMessage(error?.msg),
	});

	const handleContinueClick = () =>
		verifyIDMutate({
			verification_alert_type: "sms",
			id_number: nin,
			type: "nin",
			reference: transactionRef,
		});

	return (
		<div className="flex flex-col pb-4">
			<LCNavbar hasBack handleBackClick={handleBack} />
			<div className="flex flex-col items-center justify-between w-full">
				<div className="w-full text-center">
					<div className="mb-4 space-y-2">
						<h1 className="pb-1 text-lg font-semibold text-black">Verify NIN</h1>
						<p className="text-xs grotesk py-2 px-3 w-full bg-[#FFF7EB] rounded-lg text-[#875100]">
							Please note: Your NIN is just used for verification
						</p>
						<p className="text-xs grotesk text-black-80">Kindly provide your national identification number to continue</p>
					</div>
				</div>

				<form className="w-full my-4">
					<div className="space-y-6 mb-14">
						<LCInput
							type="number"
							label="National Identification Number"
							placeholder="Enter NIN"
							value={nin}
							onChange={(e) => setNin(e.target.value)}
						/>
					</div>
					<LCButton type="submit" isLoading={isSubmittingNIN} text="Continue" onClick={handleContinueClick} />
				</form>
			</div>
		</div>
	);
};

export default VerifyWithNIN;
