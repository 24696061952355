import { useState } from "react";
import LCButton from "../components/LCButton";
import LCInput from "../components/LCInput";
import { ID_VERIFICATION, VERIFICATION_STATUS_FAILED, VERIFY_ID_VER_CODE } from "../constants/screen";
import LCNavbar from "../containers/LCNavbar";
import { useAppContext } from "src/context/AppContext";
import { useTransactionContext } from "src/context/TransactionContext";
import { useMutation } from "@tanstack/react-query";
import { verifyID } from "src/api";
import { getCountryPhoneCode } from "src/utility";

const VerifyWithBVN = ({
	handleSubmittedPhoneNumber,
	handleSubmittedBVN,
}: {
	handleSubmittedPhoneNumber: (phone: string) => void;
	handleSubmittedBVN: (bvn: string) => void;
}) => {
	const { setCurrentScreen } = useAppContext();

	const { info: transactionInfo, transactionRef } = useTransactionContext();

	const { mutate: verifyIDMutate, isLoading: isSubmittingForm } = useMutation(verifyID, {
		onSuccess: () => setCurrentScreen(VERIFY_ID_VER_CODE),
		onError: () => setCurrentScreen(VERIFICATION_STATUS_FAILED),
	});

	const [bvn, setBvn] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const handleBack = () => setCurrentScreen(ID_VERIFICATION);

	const handleContinueClick = () => {
		handleSubmittedPhoneNumber(phoneNumber);
		handleSubmittedBVN(bvn);
		verifyIDMutate({
			verification_alert_type: "sms",
			id_number: bvn,
			type: "bvn",
			reference: transactionRef,
			phone_number: `${getCountryPhoneCode(transactionInfo?.country_code)}${phoneNumber}`,
		});
	};

	const isFormValueValid = () => bvn.length === 11 && phoneNumber.length === 10;

	return (
		<div className="flex flex-col pb-4">
			<LCNavbar hasBack handleBackClick={handleBack} />
			<div className="flex flex-col items-center justify-between w-full">
				<div className="w-full text-center">
					<div className="mb-4 space-y-2">
						<h1 className="pb-1 text-lg font-semibold text-black">Verify BVN</h1>
						<p className="text-xs py-2 px-3 w-full bg-[#FFF7EB] rounded-lg text-[#875100] grotesk">
							Please note: Your BVN is just used for verification
						</p>
						<p className="text-xs grotesk text-black-80">Kindly provide your bank verification number and phone number to continue</p>
					</div>
				</div>

				<form className="w-full my-4">
					<div className="space-y-7 mb-14">
						<LCInput
							type="number"
							label="Bank Verification Number"
							placeholder="Enter BVN"
							value={bvn}
							onChange={(e) => setBvn(e.target.value)}
						/>
						<LCInput
							type="tel"
							label="Phone number"
							placeholder="*** *** ****"
							value={phoneNumber}
							onChange={(e) => {
								if (e.target.value.length > 10) return; // For Nigerian Phone numbers
								setPhoneNumber(e.target.value);
							}}
						/>
					</div>
					<LCButton
						type="submit"
						isLoading={isSubmittingForm}
						isDisabled={!isFormValueValid()}
						text="Continue"
						onClick={handleContinueClick}
					/>
				</form>
			</div>
		</div>
	);
};

export default VerifyWithBVN;
