import React from "react";
import { useAppContext } from "src/context/AppContext";

const LCNavItem = ({
	isActive = false,
	icon,
	text,
	extraText,
	onClick,
}: {
	isActive?: boolean;
	icon: string;
	text: string;
	extraText?: string;
	onClick: React.MouseEventHandler<HTMLButtonElement>;
}) => {
	const { colour } = useAppContext();

	return (
		<button
			onClick={onClick}
			className={`w-full flex justify-between items-center p-4 rounded-lg bg-white hover:border hover:border-black-10 ${
				isActive && "border border-black-40"
			}`}
		>
			<div className="flex items-center space-x-2">
				<i
					className={`${icon} ${
						isActive ? "ri-lg" : "ri-lg p-1 bg-[#ECECEC] text-black-80 rounded-full w-8 h-8 flex items-center justify-center"
					}`}
					style={{ color: colour.dark }}
				></i>
				<p className="text-xs font-medium text-black">{text}</p>
			</div>
			<div className="flex items-center space-x-1 text-right">
				<p className="text-[10px] text-black-20 font-medium">{extraText}</p>
				<i className="ri-arrow-right-s-line ri-lg text-black-40"></i>
			</div>
		</button>
	);
};

export default LCNavItem;
