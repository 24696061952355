import { useEffect, useState } from "react";
import LCButton from "../components/LCButton";
import LCInput from "../components/LCInput";
import LCTitleSubtitle from "../components/LCTitleSubtitle";
import { ENTER_VERIFICATION_CODE, INITIATE_PURCHASE } from "../constants/screen";
import LCNavbar from "../containers/LCNavbar";
import { emailRegex } from "../utility";
import { useAppContext } from "src/context/AppContext";
import { useTransactionContext } from "src/context/TransactionContext";
import { useMutation } from "@tanstack/react-query";
import { setTransactionEmail } from "../api";

const EnterEmail = ({ setEmailSubmitted }: { setEmailSubmitted: React.Dispatch<React.SetStateAction<string>> }) => {
	const { setCurrentScreen, previousScreen, setErrorMessage } = useAppContext();
	const { info, transactionRef } = useTransactionContext();

	const { mutateAsync: setTransactionEmailMutate, isLoading: setEmailLoading } = useMutation(setTransactionEmail);

	const isFieldFixed = (field: string) => info?.fixed_properties?.includes(field);

	const [email, setEmail] = useState(info?.email || "");

	useEffect(() => {
		// ? if you get to this page and email is fixed and the previous screen was the verification page redirect to initiate purchase
		if (isFieldFixed("email") && previousScreen === ENTER_VERIFICATION_CODE) {
			setCurrentScreen(INITIATE_PURCHASE);
			return;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleContinueClick = async () => {
		try {
			await setTransactionEmailMutate({ email, reference: transactionRef });
			setEmailSubmitted(email);
			setCurrentScreen(ENTER_VERIFICATION_CODE);
		} catch (error: any) {
			setErrorMessage(error.message);
		}
	};

	const handleBackClick = () => setCurrentScreen(INITIATE_PURCHASE);

	const isEmailValid = () => !!email.trim().length && emailRegex.test(email);

	return (
		<div className="flex flex-col">
			<LCNavbar hasBack handleBackClick={handleBackClick} />
			<form className="flex flex-col items-center justify-between w-full">
				<div className="w-full mb-32 text-center">
					<LCTitleSubtitle title={info?.merchant_name} subtitle=" Kindly provide your email address to continue" />
					<LCInput
						type="email"
						label="Email Address"
						placeholder="johnsmith@xyz.com"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</div>
				<LCButton type="submit" isDisabled={!isEmailValid()} isLoading={setEmailLoading} text="Continue" onClick={handleContinueClick} />
			</form>
		</div>
	);
};

export default EnterEmail;
