// NOTE: accurate up to 20 dp.
// NOTE: Rounds down if it ever needs to. Rounding down safer than rounding up. It's better you omit money than create money from thin air.

export const add = (numbers: (number | string)[], positions = 2) => {
  const maxPosition = 16;
  const maxFactor = Math.pow(10, maxPosition);

  const factor = Math.pow(10, positions);

  const sum = numbers.reduce((acc, num) => {
    const fixedNum = Number(num).toFixed(maxPosition);
    //prettier-ignore
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return acc + Number(fixedNum) * maxFactor;
  }, 0);

  //prettier-ignore
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const result = Math.floor(sum / maxFactor * factor) / factor;

  return result.toFixed(positions);
};

export const sub = (start: number | string, numbers: (number | string)[], positions = 2) => {
  const maxPosition = 16;
  const maxFactor = Math.pow(10, maxPosition);

  const factor = Math.pow(10, positions);

  const sum = add(numbers.slice(0), positions);

  //prettier-ignore
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const ans = (Number(start).toFixed(maxPosition) * maxFactor - Number(sum).toFixed(maxPosition) * maxFactor) / maxFactor;

  const result = Math.floor(ans * factor) / factor;

  return result.toFixed(positions);
};

export const div = (num: any, den: any, positions = 2) => {
  const factor = Math.pow(10, positions);
  const ans = Number(num) / Number(den);
  const formatted = Math.floor(ans * factor) / factor;
  return formatted.toFixed(positions);
};

export const mul = (a: any, b: any, positions = 2) => {
  const factor = Math.pow(10, positions);
  const ans = Number(a) * Number(b);
  const formatted = Math.floor(ans * factor) / factor;
  return formatted.toFixed(positions);
};
