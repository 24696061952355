import { useState } from "react";
import { DropDownDatatype } from "../components/LCDropdown";
import LCDropdownListItem from "../components/LCDropdownListItem";
import LCInput from "../components/LCInput";
import { popularCoinsListFn } from "../constants/transaction";
import { before_, findCoinLogo, getLogoFor } from "../utility";
import { useAppContext } from "src/context/AppContext";

interface ILCDropdownListSearchable {
	searchLabel: string;
	searchPlaceholder: string;
	allOptions: DropDownDatatype[];
	onOptionSelect: (v: any) => void;
	onClose: (v: boolean) => void;
}

const LCDropdownListSearchable = ({ searchLabel, searchPlaceholder, allOptions, onOptionSelect, onClose }: ILCDropdownListSearchable) => {
	const { isPopup } = useAppContext();
	const [searchParam, setSearchParam] = useState("");
	const [optionsToDisplay, setOptionsToDisplay] = useState(allOptions);

	const handleSearchParamChange = (value: string) => {
		setSearchParam(value);
		if (!value.trim().length) {
			setOptionsToDisplay(allOptions);
			return;
		}
		const queryToLowercase = value.toLowerCase();
		const rankedIndex = allOptions
			.filter(
				(option) =>
					option?.coin_name?.toLowerCase()?.includes(queryToLowercase) ||
					option?.chain?.toLowerCase()?.includes(queryToLowercase) ||
					option?.chain_name?.toLowerCase()?.includes(queryToLowercase) ||
					option?.currency?.toLowerCase()?.includes(queryToLowercase) ||
					option?.country_code?.toLowerCase()?.includes(queryToLowercase) ||
					option?.fiat?.toLowerCase()?.includes(queryToLowercase) ||
					option?.name?.toLowerCase()?.includes(queryToLowercase)
			)
			.map((option) => {
				let points = 0;
				if (before_(option?.coin_name).toLowerCase().includes(queryToLowercase)) {
					points += 2;
				}
				if (option?.name?.toLowerCase().includes(queryToLowercase)) {
					points += 1;
				}

				return { ...option, points };
			})
			.sort((a, b) => b.points - a.points);
		setOptionsToDisplay(rankedIndex);
	};

	return (
		<div className="absolute top-0 left-[-12px] right-0 h-full w-full bg-white pb-7 rounded-3xl z-[60]">
			<div className="absolute w-full bg-white rounded-3xl pt-7">
				<div className="flex items-center justify-between px-8 pt-2 mb-6">
					<p className="text-base font-semibold text-black">Select Currency</p>
					<button onClick={() => onClose(false)}>
						<i className="ri-close-fill ri-xl" />
					</button>
				</div>
			</div>
			<div className="mt-20 space-y-4">
				<div className="pl-8 sticky w-[288px] bg-white">
					<LCInput
						label={searchLabel}
						placeholder={searchPlaceholder}
						value={searchParam}
						onChange={(e) => handleSearchParamChange(e.target.value)}
					/>
				</div>
				<div className={`space-y-3 pt-3 ${isPopup ? "max-h-[220px]" : "max-h-[250px]"} overflow-y-auto`}>
					{!searchParam.length &&
						(popularCoinsListFn(allOptions).length ? (
							<div>
								<p className="px-8 mb-1 text-sm font-medium">Popular</p>
								{popularCoinsListFn(allOptions).map((option, index) => (
									<LCDropdownListItem logo={findCoinLogo(option)} key={index} option={option} onSelect={onOptionSelect} />
								))}
							</div>
						) : null)}

					<div>
						<p className="px-8 mb-1 text-base font-semibold">All</p>
						{optionsToDisplay.map((option, index) => (
							<LCDropdownListItem
								logo={option?.country_code ? getLogoFor(option?.country_code) : findCoinLogo(option)}
								key={index}
								option={option}
								onSelect={onOptionSelect}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default LCDropdownListSearchable;
