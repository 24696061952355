import { WarningAlert } from "src/assets/icons/others";
import LCNavbar from "../containers/LCNavbar";
import { useAppContext } from "src/context/AppContext";

const AppError = () => {
	const { colour } = useAppContext();
	return (
		<div>
			<div className="flex flex-col">
				<LCNavbar />
				<div>
					<div className="space-y-6">
						<div className="w-20 h-20 mx-auto">
							<WarningAlert />
						</div>
						<div className="space-y-2 text-center">
							<h1 className="text-xl font-semibold text-black">Hmm, something went wrong</h1>
							<p className="w-full mx-auto max-w-[284px]">Click the reload button below.</p>
						</div>
						<button
							onClick={() => window.location.reload()}
							className="flex items-center justify-center w-full space-x-2 outline-none"
							style={{ color: colour.dark }}
						>
							<i className="ri-restart-line ri-lg"></i>
							<p className="text-base font-medium">Reload</p>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppError;
