import React from "react";
import { getLogoFor, findCoinLogo } from "../utility";
import { DropDownDatatype } from "./LCDropdown";
import LCDropdownListItem from "./LCDropdownListItem";

const LCDropdownList = ({
	options,
	title,
	onClose,
	onOptionSelect,
}: {
	options: DropDownDatatype[];
	title: string;
	onClose: (v: boolean) => void;
	onOptionSelect: (v: any) => void;
}) => {
	return (
		<div className="absolute top-0 left-[-12px] right-0 h-full w-full bg-white pb-7 rounded-3xl z-[60]">
			<div className="absolute w-full bg-white rounded-3xl pt-7">
				<div className="flex items-center justify-between px-8 pt-2 mb-6">
					<p className="text-base font-semibold text-black">{title}</p>
					<button onClick={() => onClose(false)}>
						<i className="ri-close-fill ri-xl" />
					</button>
				</div>
			</div>
			<div className="mt-20 overflow-y-auto h-[360px]">
				{options.map((option, index) => (
					<LCDropdownListItem
						logo={option?.country_code ? getLogoFor(option?.country_code) : findCoinLogo(option)}
						key={index}
						option={option}
						onSelect={onOptionSelect}
					/>
				))}
			</div>
		</div>
	);
};

export default LCDropdownList;
